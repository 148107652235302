import React, { Component } from 'react'
import colors from '../../lib/helpers/colors'
import { BarChart, PieChart } from 'react-chartkick'
import 'chart.js'
import color from '@mui/material/colors/amber'

const factors = ["Factor 1: Leadership and a Compassionate School Community", "Factor 2: Conscious Awareness of Emotions and Stress", "Factor 3: Courage & Resiliency", "Factor 4: Confidence & Positivity", "Factor 5: Understanding of Equity"]

const GradeBreakdown = (props) => {

    const data = props.data;

    const gradesData = []

    /*factors.forEach((factor, i) => {
        gradesData.push({
            title:"Factor Averages By Grade - " + factor,
            data: data[0].gradeLevelResults.filter(grade => grade.percent != 0).map(grade => [grade.label, grade.averages["factor"+(i+1)+"Average"]])
        })
    })

   
*/

    const gradeData = []

    data[0].gradeLevelResults.forEach(res => {
        var points = {}

        factors.forEach((factor,i) => {
            points[factor] = res.averages["factor"+(i+1)+"Average"]
        })

        if(res.percent > 0)
        {
            gradeData.push({
                name:res.label,
                data:points
            })
        }
    })

    var breakdownData = data[0].gradeLevelResults.filter(x => x.percent > 0).map(x => [x.label + " (" + Number(x.percent*100).toFixed(1)+"%)", x.percent*100])

    return (
        <div className="grade-breakdown">
            <div id="grade-pie-chart"><PieChart width={800} height={400} donut={true} data={breakdownData} colors={colors.comparison_colors} title="Grade Breakdown" /></div>

            <div id="grade-factor-breakdown">
                <BarChart id="component_factor_graph" width={800} height={450} colors={colors.comparison_colors} data={gradeData} max={4} title={"Factor Averages by Grade"} library={{
                            scales: {
                            yAxes: [
                                {
                                    ticks: { autoSkip:false }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: { autoSkip:false }   
                                }
                            ]
                            }
                        }}/>
            </div>
        </div>
    )
}

export default GradeBreakdown;