import React from "react";
import { Button } from "@mui/material";
import pricing from "../../../pdf/pricing.pdf";
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";

export default function Pricing(props) {
  const submit = () => {
    window.location.href = "/Pricing/Order/";
  };

  var orderText = props.loggedOut
    ? "Get Started for Free!"
    : "Ordering Information";

  const download = () => {
    fetch(pricing).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "S-CCATEPricingGuide.pdf";
        alink.click();
      });
    });
  };

  return (
    <div>
      <h3>
        Products and Pricing
        <a className="download" onClick={download}>
          Download Pricing Guide
        </a>
      </h3>
      <div className="welcome">
        <p>
          <b>
            As part of the Compassionate School Leadership Project (CSLA),
            schools can access S-CCATE and the S-CCATE Pro Package at no charge
            beginning January 2023.
          </b>
        </p>
        <b>This includes:</b>
        <ul className="welcome-list">
          <li>
            Schools participating in the National Feasability Study between{" "}
            <b>January - August, 2023</b>.
          </li>
          <li>
            Schools participating in our National Survey between{" "}
            <b>March - September, 2023</b>.
          </li>
          <li>
            Schools participating in our national research study between{" "}
            <b>September, 2023 - July, 2024</b>.
          </li>
        </ul>
        <p>
          Contact Dr. Martha Staeheli at <b>martha.staeheli@yale.edu</b>
        </p>
        {props.loggedOut && (
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => submit()}
              variant="contained"
              color="secondary"
              className="welcome-login"
            >
              Get Started For Free
            </Button>
          </div>
        )}
        <br />
        <b>Contact s-ccate@edimprovement.org</b> for assistance ordering a
        Basic, Pro, or Pro Plus package.
        <br />
        <br />
        The Parties agree to an initial base price{" "}
        <b>per year, per customer group</b> as follows:
        <PricingTable highlightColor="#1976D2">
          <PricingSlot
            buttonText={orderText}
            title="Basic"
            priceText="$1,050-$3,750/yr"
          >
            <PricingDetail>
              <b>7-49</b> respondents ($1,050)
            </PricingDetail>
            <PricingDetail>
              <b>50-99</b> respondents ($2,500)
            </PricingDetail>
            <PricingDetail>
              <b>100-199</b> respondents ($3,750)
            </PricingDetail>
            <PricingDetail> Basic Report with Charts</PricingDetail>
            <PricingDetail> National Average Comparison</PricingDetail>
            <PricingDetail> Strengths and Needs</PricingDetail>
            <PricingDetail> Basic Recommendations</PricingDetail>
            <PricingDetail> Monthly eNewsletter</PricingDetail>
          </PricingSlot>
          <PricingSlot
            buttonText={orderText}
            title="Pro"
            priceText="Basic + $600/yr"
          >
            <PricingDetail>
              <b>7-49</b> respondents ($1,650)
            </PricingDetail>
            <PricingDetail>
              <b>50-99</b> respondents ($3,100)
            </PricingDetail>
            <PricingDetail>
              <b>100-199</b> respondents ($4,350)
            </PricingDetail>
            <PricingDetail> Basic Report with Charts</PricingDetail>
            <PricingDetail> National Average Comparison</PricingDetail>
            <PricingDetail> Strengths and Needs</PricingDetail>
            <PricingDetail> Basic Recommendations</PricingDetail>
            <PricingDetail> Monthly eNewsletter</PricingDetail>
            <PricingDetail> Round One Action Guide</PricingDetail>
          </PricingSlot>
          <PricingSlot
            highlighted
            buttonText={orderText}
            title="Pro Plus"
            priceText="Basic + $1,200/yr"
          >
            <PricingDetail>
              <b>7-49</b> respondents ($2,250)
            </PricingDetail>
            <PricingDetail>
              <b>50-99</b> respondents ($3,700)
            </PricingDetail>
            <PricingDetail>
              <b>100-199</b> respondents ($4,950)
            </PricingDetail>
            <PricingDetail> Basic Report with Charts</PricingDetail>
            <PricingDetail> National Average Comparison</PricingDetail>
            <PricingDetail> Strengths and Needs</PricingDetail>
            <PricingDetail> Basic Recommendations</PricingDetail>
            <PricingDetail> Monthly eNewsletter</PricingDetail>
            <PricingDetail> Round One Action Guide</PricingDetail>
            <PricingDetail> Round Two and Three Action Guides</PricingDetail>
          </PricingSlot>
          <PricingSlot
            buttonText="Contact Us"
            title="Custom"
            priceText="Contact Us"
          >
            <PricingDetail>
              Yearly/Multi-Year support packages available
            </PricingDetail>
            <PricingDetail>
              <b>Email:</b> s-ccate@edimprovement.org
            </PricingDetail>
            <PricingDetail>
              <b>Phone:</b> 1-833-4SCCATE
            </PricingDetail>
          </PricingSlot>
        </PricingTable>
        <div className="additional-options">
          <h4>Additional Resources and Service Options</h4>
          <table>
            <tbody>
              <tr>
                <td>Publication Resources</td>
                <td>
                  Regularly priced at $34.95/copy; Up to 25% discount on books
                  to accompany implementation of S-CCATE recommendations
                </td>
              </tr>
              <tr>
                <td>Book Club</td>
                <td>A Six Part Series $80.00 pp, Group discounts available.</td>
              </tr>
              <tr>
                <td>Core Learning Team Guidance</td>
                <td>$1,200.00 - $2,800.00</td>
              </tr>
              <tr>
                <td>
                  Dedicated CEI Educational Consultant, Facilitator, or Trainer
                </td>
                <td>
                  Contact us to discuss customized package options;
                  Yearly/Multi-Year support packages available
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="more-info">
        For more information, to order, or to discuss customized S-CCATE options
        for your customer group, contact the Center for Educational Improvement
        at s-ccate@edimprovement.org.
      </div>
    </div>
  );
}
