import React, { Component } from 'react'
import colors from '../../lib/helpers/colors'
import { BarChart } from 'react-chartkick'
import 'chart.js'

const FactorBar = (props) => {
    
    const data = props.hasPrev ? 
        props.data.filter((x,i) => i == 0 || i == 1 || i == 3) :
        props.data.filter((x,i) => i == 0 || i == 2);

    const comparisonData = {
        title:"Factor Averages",
        datasets: data.map((entity, i) => {
                var name = entity.entity.name
                    if(props.hasPrev)
                    {
                        if(i == 0)
                        {
                            name = name + " " + entity.academicYear + " - " + (entity.type == "1" ? "Start of Year" : entity.type == "2" ? "Mid-Year" : "End of Year")
                        }
                        else if(i == 1 && props.data.length > 2)
                        {
                            name = name + " " + entity.academicYear + " - " + (entity.type == "1" ? "Start of Year" : entity.type == "2" ? "Mid-Year" : "End of Year")
                        }
                        else
                        {
                            name = "National Average"
                        }
                    }
                    return {
                        name: name == "CT,MA,ME,NH,RI,VT" ? "New England" : name,
                        data : {
                            "1. Leadership and a Compassionate School Community": entity.averages.factor1Average,
                            "2. Conscious Awareness of Emotions and Stress": entity.averages.factor2Average,
                            "3. Courage & Resiliency": entity.averages.factor3Average,
                            "4. Confidence & Positivity": entity.averages.factor4Average,
                            "5. Understanding of Equity": entity.averages.factor5Average
                        }
                    }
                })
            
    }

    return (
        <div id="factor-bar">
            <BarChart id="component_factor_graph" width={800} height={425} discrete={true} colors={colors.show_prev_colors} data={comparisonData.datasets} max={4} title={comparisonData.title} library={{
                scales: {
                yAxes: [
                    {
                        ticks: { autoSkip:false }
                    }
                ],
                xAxes: [
                    {
                        ticks: { autoSkip:false }   
                    }
                ]
                }
            }}/>
        </div>
    )
}

export default FactorBar;