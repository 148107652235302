import React from "react";
import { NavLink } from "react-router-dom";
import timeline from "../../../img/timeline.jpg";
import chris from "../../../img/chris-headshot.jpg";
import signature from "../../../img/signature.jpg";
import casel from "../../../pdf/casel.pdf";
import infog from "../../../img/infographic.jpg";

export default function Value(props) {
  return (
    <div>
      <h3>Value of S-CCATE</h3>
      <div className="welcome" style={{ fontSize: 15, lineHeight: 1.7 }}>
        <h4 style={{ marginTop: 0, fontSize: 20 }}>
          S-CCATE, Stress, Trauma, and COVID-19
        </h4>
        <p>
          Today, as we face large-scale global crises, we all are affected by
          stress and trauma, with multi-layered impacts of fears, inequities,
          injustice, trauma, and uncertainty. Adding more compassion to school,
          as evidenced by recent research on neurobiology, cultures can provide
          much needed relief to students, families, and educators. Compassionate
          practices can enhance self-care, well-being, learning, and resiliency.
          S-CCATE is relevant and easy to use for rating school culture with
          in-class or remote instruction. For remote learning, please consider
          “classrooms” to also mean “virtual classrooms” and instruction and
          communities to refer to "virtual instruction and virtual communities".
        </p>
        <div className="welcome-center">
          <img
            style={{ marginTop: 15, maxWidth: "50%", alignSelf: "center" }}
            src={infog}
          />
        </div>
        <h4 style={{ fontSize: 20 }}>Timeline</h4>
        <div className="welcome-center">
          <img
            style={{
              maxWidth: "75%",
              marginTop: 10,
            }}
            src={timeline}
          />
        </div>
        <h4 style={{ marginTop: "15px", fontSize: 20 }}>
          Letter to the Educators
        </h4>
        <p>
          Key advantages of S-CCATE in comparison to other school climate or
          culture tools are
        </p>
        <div>
        <ul className="welcome-list">
          <li>
            It is an evidenced-based approach validated with a sample of over
            800 educators, across Pre-K-12 grades.
          </li>
          <li>
            S-CCATE is a quick survey designed to be completed online in 12-15
            minutes by educators.
          </li>
          <li>
            Powered by TeachLink, S-CCATE data are maintained on a secure
            platform.
          </li>
          <li>
            It complements CASEL’s SEL Core Competencies. Here is the{" "}
            <a target="_blank" href={casel}>
              Heart Centered Learning CASEL Crosswalk comparison
            </a>
            .
          </li>
          <li>
            With S-CCATE Basic you will receive data, graphic and tabular
            summaries, and recommendations to address your school or district's
            needs.
          </li>
          <li>
            We provide comparative data – comparing your school/district’s data
            to other schools in your district and to our national sample.
          </li>
          <li>
            S-CCATE Basic gives you up to three (beginning of year, interim, and
            end of the year) reports annually.
          </li>
          <li>
            With S-CCATE Basic, you will also receive our monthly eNewsletter
            containing research and practical advice on improving school
            culture.
          </li>
          <li>
            Options to also purchase the S-CCATE Action Guides and service
            packages with further discounts.
          </li>
          <li>
            Our team's availability to provide consultation and facilitation at
            your school/district and online.
          </li>
        </ul>

        </div>
        <p>
          Today, schools are bombarded with stress as educators struggle to
          raise student achievement and address the many, multi-layered impacts
          of violence, injustice, and trauma faced by so many. We believe, and
          significant research supports our belief, that adding more compassion
          to schools can provide much needed relief to students, families, and
          educators, freeing minds for academic learning. Supporting research
          can be found in our recent publications; we see a consistent flow of
          related research, all suggesting that schools can be a crucial
          component in supporting the well-being of children, youth, and adults.
        </p>
        <p>
          We thank you for considering S-CCATE. To receive results for your
          school or district, at least 7 of your staff must have responded to
          S-CCATE.
        </p>
        <br />
        <br />
        <div className="welcome-center">
          <img src={chris} width="175" />
          <br />
          <img src={signature} width="175" />
          <br />
          <p>
            <b>Christine Mason, Ph.D.</b>
            <br />
            Center for Educational Improvement
            <br />
            www.edimprovement.org, chrismason@edimprovement.org
          </p>
        </div>
        <br />
        <hr />
        <h4 style={{ fontSize: 20 }}>Value</h4>
        Together, S-CCATE and the S-CCATE Supplement:
        <ul className="welcome-list">
          <li>
            Provide educators with data for items that are based on substantial
            research on the impact of trauma and stress; the importance of
            compassionate communities and nurturing adults; and the positive
            impact of mindfulness.
          </li>
          <li>
            Are based on over six years of focus groups and input from school
            leaders and pilot implementation at three sites.
          </li>
          <li>
            Provide baseline information with easy-to-interpret indicators for
            areas for intervention.
          </li>
          <li>
            Render a unique assessment of educators’ perception of their
            personal knowledge and skills and their perception of student and
            schoolwide strengths and needs.
          </li>
          <li>
            Include factors related to trauma, neuroscience, cultural
            competence, equity, mindfulness, and school policies.
          </li>
          <li>
            Support “teacher empowerment” to guide teachers in deciding upon
            professional development activities and interventions to support
            compassionate school cultures.
          </li>
        </ul>
        <h4 style={{ fontSize: 20 }}>
          For School Administrators/Points of Contact
        </h4>
        As school administrators, you will be primary contacts for S-CCATE and
        will have access to a variety of tools for different views and
        comparisons, including comparison with other schools in your state,
        district, or nationally. However, individual responses will be held in
        confidence. S-CCATE can be made easily available to your staff. To get
        started, please provide us a list of staff with their e-mail addresses
        and your school and district affiliation. View S-CCATE{" "}
        <NavLink to="/pricing">products and pricing</NavLink> for additional
        information.
        <h4 style={{ fontSize: 20 }}>
          For District Wide Administration of S-CCATE
        </h4>
        District wide administration of S-CCATE will not only provide cost
        savings, but will also provide the ability to compare data across
        schools and how they compare to the district average. Contact{" "}
        <b>martha.staeheli@yale.edu</b> for information on our sliding scale
        and cost savings and discounts for districts.
        <h4 style={{ fontSize: 20 }}>Once you have completed S-CCATE</h4>
        You can gain access to Basic S-CCATE reports, including graphs and
        comparative national data, with identification of areas of strength, and
        recommendations for areas of needs, by{" "}
        <NavLink to="/pricing">completing an order</NavLink>. If you are
        participating as part of our CSLA program, we will contact you with your
        results. Optional services are also available to assist you and your
        school/district in addressing your needs and helping to develop more
        compassionate school environments. Check out our{" "}
        <NavLink to="/pricing">S-CCATE Suite of Services</NavLink>.
      </div>
    </div>
  );
}
