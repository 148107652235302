
 //const chart_colors_hex = ["#ffffd9", "#edf8b1", "#c7e9b4", "#7fcdbb", "#41b6c4", "#1d91c0", "#225ea8", "#253494", "#081d58"]//blue single
 //const chart_colors_hex = ["#081d58", "#253494", "#225ea8", "#1d91c0", "#41b6c4", "#7fcdbb", "#c7e9b4", "#edf8b1", "#ffffd9"]//blue reverseconst
 //const chart_colors_hex = ["#3F51B5", "#FFEB3B", "#4CAF50", "#F44336", "#E91E63", "#9C27B0","#2196F3", "#00BCD4", "#009688", "#CDDC39", "#FFC107", "#FF9800", "#FF5722", "#607D8B"]  //multi
 //const chart_colors_hex = ["#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600"]//purp orange
 //const chart_colors_hex = ["#de0405","#F49804","#facd10","#0cb535","#2c67f6","#5B23D5"]//rainbow const
 //const chart_colors_hex = ["#800026", "#bd0026", "#e31a1c", "#fc4e2a", "#fd8d3c", "#feb24c", "#fed976", "#ffeda0", "#ffffcc"] //orange single 
 const chart_colors_hex = ["#974994", "#16b0dd", "fd931d", "#f7bc24", "#68c74f"]

 // component 1 red rgba(222, 4, 5, 0.9) rgba(222, 4, 5, 0.5)
 // orange rgba(244, 152, 4, 0.9) rgba(244, 152, 4, 0.5)
 // component 2 yellow rgba(250, 205, 16, 0.9) rgba(250, 205, 16, 0.5)
 // component 3 green rgba(12, 181, 53, 0.9) rgba(12, 181, 53, 0.5)
 // component 4 blue rgba(44, 103, 246, 0.9) rgba(44, 103, 246, 0.5)
 // component 5 purple rgba(91, 35, 213, 0.9) rgba(91, 35, 213, 0.5)
 
// pastel red (255,179,186,1)
// pastel orange (255,223,186,1)
// pastel yellow (186,255,201,1)
// pastel green (186,225,255,1)
// pastel blue (224,187,228,1)
// pastel purple rgba(224, 187, 228, 1)

function hex_to_rgba(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

function chart_colors_rgba(alpha){
    return chart_colors_hex.map((color) => hex_to_rgba(color, alpha))
}

const color_primary = chart_colors_rgba(.9)[1]
const color_primary_faded = chart_colors_rgba(.9)[1]
const white = "#ffffff"


const show_prev_colors = ["rgba(114,147,203,1)", "rgba(114,147,203,0.4)", "rgba(0,0,0,0.3)"]

const comparison_colors = ["rgba(114,147,203,1)", "rgba(225,151,75,1)", "rgba(132,186,91,.7)", "rgba(211,94,96,0.7)", "rgba(10,19,87,0.7)", "rgba(144,103,157,0.7)", "rgba(165,171,83,0.7)", "rgba(230,25,75,.8)",
"rgba(60,180,75,.8)",
"rgba(255,225,25,.8)",
"rgba(67,99,216,.8)",
"rgba(245,130,49,.8)",
"rgba(145,30,180,.8)",
"rgba(70,240,240,.8)",
"rgba(240,50,230,.8)",
"rgba(188,246,12,.8)",
"rgba(250,190,190,.8)",
"rgba(0,128,128,.8)",
"rgba(230,190,255,.8)",
"rgba(154,99,36,.8)",
"rgba(255,250,200,.8)",
"rgba(128,0,0,.8)",
"rgba(170,255,195,.8)",
"rgba(128,128,0,.8)",
"rgba(255,216,177,.8)",
"rgba(0,0,117,.8)",
"rgba(128,128,128,.8)"  ]

const component_colors = [
                          {primary:"rgba(211,94,96,1)", secondary:"rgba(211,94,96,0.6)"}, // red
                          {primary:"rgba(225,151,75,1", secondary:"rgba(225,151,75,0.6)"}, // orange
                          {primary:"rgba(132,186,91,1)", secondary:"rgba(132,186,91,0.6)"}, // green
                          {primary:"rgba(114,147,203,1)", secondary:"rgba(114,147,203,0.6)"}, // blue
                          {primary:"rgba(144,103,157,1)", secondary:"rgba(144,103,157,0.6)"} // purple
                        ]
const grade_level_colors = ["rgba(114,147,203,1)", "rgba(114,147,203,1)", 
                            "rgba(225,151,75,1)", "rgba(225,151,75,1)", "rgba(225,151,75,1)", "rgba(225,151,75,1)", "rgba(225,151,75,1)",
                            "rgba(132,186,91,0.7)", "rgba(132,186,91,0.7)", "rgba(132,186,91,0.7)", 
                            "rgba(211,94,96,0.7)", "rgba(211,94,96,0.7)", "rgba(211,94,96,0.7)", "rgba(211,94,96,0.7)", "rgba(144,103,157,0.7)"]


module.exports = {
    comparison_colors,
    component_colors,
    hex_to_rgba,
    chart_colors_hex,
    chart_colors_rgba,
    color_primary,
    color_primary_faded,
    white,
    grade_level_colors,
    show_prev_colors
}