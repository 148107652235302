import React, { useState, useEffect } from 'react';
import tl from '../../lib/teachlink/TeachLinkAPI'
import Loading from '../general/Loading';
import { Redirect } from "react-router-dom";
import GenericSnackbar from '../snackbar/GenericSnackbar';

import '../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import Login from './Login';
import { Button } from '@mui/material';

export default function InviteLogin(props) {
  const [loading, setLoading] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const [invite, setInvite] = useState(null);
  const [accepted, setAccepted] = useState(false);
  const [acceptFailed, setAcceptFailed] = useState(false);

  useEffect(() => {
    tl.get_invite_user(props.match.params.id).then((invite) => {
        setInvite(invite)
        setLoading(false)
    }).catch(() => {
        setInvalid(true)
        setLoading(false)
    })
  }, [])

  const acceptInvite = () => {
    setLoading(true)
    tl.accept_manager_invite(invite.invite_id).then(() => {
      setAccepted(true)
      setLoading(false)
    }).catch(() => {
      setAcceptFailed(true)
      setLoading(false)
    })
  }

  if(loading)
  {
    return <Loading />
  }

  if((invalid && !props.isAuthed) || (props.isAuthed && acceptFailed))
  {
      return (
          <div style={{textAlign:"center", marginTop:40}}>
              <h3>Invite has been already accepted</h3>
              Sorry, it appears as though the invite link you are trying to access has either already been accepted or is invalid<br /><br />
              <a href="/Login">Login here</a> to continue with S-CCATE
          </div>
      )
  }

  if(accepted && invite && invite.as_manager)
  {
      setTimeout(() => {
        window.location.href = "/surveys"
      }, 1000);
  }

  if(invite && invite.as_manager && props.isAuthed)
  {
      return (
        <div style={{textAlign:"center", marginTop:40}}>
            <h3>Accept Invitation</h3>
            You have been invited as an S-CCATE manager for {invite.school_name}<br /><br />
            <Button disabled={accepted} variant="contained" color="primary" onClick={() => acceptInvite()}>Click Here to Accept</Button>     
            <GenericSnackbar open={accepted} message={"Invite Accepted, Redirecting"} variant="success" />
        </div>
      )
  }

  if(props.isAuthed && !invite) {
    return <Redirect to="/" />
  }

  if(props.isAuthed && invite.as_manager)
  {
    return <Redirect to="/surveys" />
  }

  if(props.isAuthed)
  {
    return <Redirect to="/surveys" />
  }

  return <Login email={invite.email} invite_key={props.match.params.id} register={!invite.is_user}/>
}