import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: "#d32f2f",
  },
  info: {
    backgroundColor: "#1769aa",
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: "10px",
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default function GenericSnackbar(props) {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <Snackbar
        anchorOrigin={{
        vertical: props.vertical || 'bottom',
        horizontal: props.horizontal || 'left',
        }}
        open={props.open}
        autoHideDuration={6000}
        onClose={props.handleClose}>
        <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
            <span id="client-snackbar" className={classes.message}>
              <Icon style={{color:"#ffffff", background:"none"}} className={clsx(classes.icon, classes.iconVariant)} />
              {message}
            </span>
        }
        action={[
            <IconButton key="close" aria-label="Close" style={{color:"#ffffff", background:"none"}} onClick={onClose}>
            <CloseIcon className={classes.icon} />
            </IconButton>,
        ]}
        {...other}
        />
    </Snackbar>
  );
}

GenericSnackbar.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};