import React from 'react';
import '../../App.css';
import Survey from '../general/Survey';
import tl from '../../lib/teachlink/TeachLinkAPI'
import Loading from '../general/Loading';
import queryString from 'query-string';

export default class TakeSurvey extends React.Component {

  constructor(props)
  {
    super(props)
    this.response_id = props.match.params.id
    this.state = {
      loading:true,
      saving:false,
      title:""
    }
  }

  onAnswerChanged = (element, answer, options, explain_answer) => {
    this.setState({saving:true})
    if(this.state.code)
    {
      tl.save_answer_anon(this.state.response.id, element.id, this.state.response.survey_id, answer, explain_answer, options ? options.join(",") : "").then((response) => {
        this.setState({saving:false, response:response})
      }).catch((err) => {
        console.log(err)
      })
    }
    else
    {
      tl.save_answer(this.state.response.id, element.id, this.state.response.survey_id, answer, explain_answer, options ? options.join(",") : "").then((response) => {
        this.setState({saving:false, response:response})
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  finalizeResponse = () => {
    this.setState({loading:true})
    if(this.state.code)
    {
      tl.finalize_response_anon(this.state.response.id).then(() => {
        this.setState({loading:false})
        const { history } = this.props;
        history.push('/thankyou')
      }).catch((err) => {
        console.log(err)
      })
    }
    else
    {
      tl.finalize_response(this.state.response.id).then(() => {
        this.setState({loading:false})
        const { history } = this.props;
        history.push('/thankyou')
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  getSurveyResponse(response_id, code) {
    if(code)
    {
      tl.get_survey_response_with_code(response_id, code).then((response) => {
        this.setState({loading:false, response:response})
      }).catch((err) => {
        console.log(err)
      })
    }
    else
    {
      tl.get_survey_response(response_id).then((response) => {
        this.setState({loading:false, response:response})
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  componentDidMount() {

    var self = this;

    let params = queryString.parse(this.props.location.search)

    if(params.code != null)
    {
      tl.start_survey_with_code(params.code).then((response_id) => {
        this.setState({code:params.code})
        self.getSurveyResponse(response_id, params.code)
      }).catch((err) => {
          console.log(err)
      })
    }
    else if(this.response_id != null)
    {
      self.getSurveyResponse(this.response_id)
    }
    else
    {
      alert("An error occurred, please try again later. If this error persists contact sales@s-ccate.org")
    }
  }


  render() {   
    if(this.state.loading)
      return <Loading />  

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const mobileView = screenHeight > screenWidth;

    return ( 
      <div className="survey">
        <div>
          <h3>Take Survey for {this.state.response.school_name} - {this.state.title}</h3>
          {
            mobileView ? (
              <></>
            ) : (
              <span className={this.state.saving ? "save-status saving" : "save-status saved"}>{this.state.saving ? "Saving Changes..." : "Changes Saved"}</span>
            )
          }
        </div>
        <div className="panel large-pad">
          <Survey id={this.state.response.survey_id} code={this.state.code} surveyTitle={(title) => {this.setState({title:title})}} onAnswerChanged={this.onAnswerChanged} response={this.state.response} submitSurvey={() => this.finalizeResponse()} />
        </div>
      </div>
    );
  }
}