import React from "react";
import "./App.css";
import Login from "./views/auth/Login";
import Confirm from "./views/auth/Confirm";
import Setup from "./views/auth/Setup";
import { connect } from "react-redux";
import tl from "./lib/teachlink/TeachLinkAPI";
import { user_logged_in, user_logged_out } from "./lib/actions";
import Main from "./views/Main";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import blue from "@mui/material/colors/blue";
import Loading from "./views/general/Loading";
import InviteLogin from "./views/auth/InviteLogin";
import Welcome from "./views/pages/Welcome";
import More from "./views/pages/static/More";
import Pricing from "./views/pages/static/Pricing";
import TakeSurvey from "./views/pages/TakeSurvey";
import Order from "./views/pages/static/Order";
import Value from "./views/pages/static/Value";
import ThankYou from "./views/pages/static/ThankYou";
import Validation from "./views/pages/static/Validation";
import SidebarMenu from "./views/general/SidebarMenu";
import NECLTC from "./views/pages/static/NECLTC";
import Button from "@mui/material/Button";
import { Menu } from "@mui/icons-material";
import { BrowserRouter, Route, Switch } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      light: "#c466c5",
      main: "#c466c5",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
  },
});

type Props = {
  dispatch: (arg0: { type: string; user?: any }) => void;
  auth: {
    loggedIn: boolean;
  };
};

type State = {
  loading: boolean;
  show: boolean;
  school_admin?: boolean;
  sccate_admin?: boolean;
};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
    };
  }

  componentDidMount() {
    tl.authorize((user: any) => {
      user
        ? this.props.dispatch(user_logged_in(user))
        : this.props.dispatch(user_logged_out());
      this.setState({ loading: false });
    });
  }

  toggleDrawer = () => () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    // If the screen is taller than wide, show mobile view
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const mobileView = screenHeight > screenWidth;

    return this.state.loading ? (
      <Loading />
    ) : (
      <ThemeProvider theme={theme}>
        <div className="header">
          {mobileView ? (
            <Button onClick={this.toggleDrawer()}>
              <Menu style={{ color: "white" }} />
            </Button>
          ) : null}
          <h4 className="title">S-CCATE</h4>
          <div className="account-info">
            {mobileView ? null : (
              <>
                Phone: 1-833-4SCCATE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            {!this.props.auth.loggedIn ? (
              <a className="btn btn-primary" href="/account">
                Login
              </a>
            ) : null}
            {this.props.auth.loggedIn && !mobileView && (
              <>
                Welcome back, {tl.get_logged_in_user().first_name} &nbsp;&nbsp;
              </>
            )}
            {this.props.auth.loggedIn && (
              <a className="btn btn-primary" onClick={() => tl.logout_user()}>
                Logout
              </a>
            )}
          </div>
        </div>
        <BrowserRouter>
          {!this.props.auth.loggedIn ? (
            <SidebarMenu
              logged_in={this.props.auth.loggedIn}
              sccate_admin={this.state.sccate_admin ?? false}
              sccate_admin_restricted={false}
              user_can_manage={false}
              show={this.state.show}
              showHandler={this.toggleDrawer()}
            />
          ) : null}
          <div
            className={
              !this.props.auth.loggedIn ? (mobileView ? "mobile" : "main") : ""
            }
          >
            <Switch>
              <Route path="/ThankYou" exact component={ThankYou} />
              <Route path="/TakeSurvey" exact component={TakeSurvey} />
              <Route
                path="/Login"
                exact
                render={(props) => (
                  <Login {...props} isAuthed={this.props.auth.loggedIn} />
                )}
              />
              <Route
                path="/SignupLogin/:email"
                exact
                render={(props) => (
                  <Login
                    {...props}
                    isAuthed={this.props.auth.loggedIn}
                    signupLogin={true}
                  />
                )}
              />
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Welcome {...props} isAuthed={this.props.auth.loggedIn} />
                  )}
                />
              ) : null}
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/scla"
                  exact
                  //@ts-ignore
                  render={(props) => <NECLTC {...props} loggedOut={true} />}
                />
              ) : null}
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/Pricing"
                  exact
                  render={(props) => <Pricing {...props} loggedOut={true} />}
                />
              ) : null}
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/More"
                  exact
                  render={(props) => (
                    <More {...props} isAuthed={this.props.auth.loggedIn} />
                  )}
                />
              ) : null}
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/Value"
                  exact
                  render={(props) => (
                    <Value {...props} isAuthed={this.props.auth.loggedIn} />
                  )}
                />
              ) : null}
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/Pricing/Order/"
                  exact
                  render={(props) => (
                    //@ts-ignore
                    <Order {...props} isAuthed={this.props.auth.loggedIn} />
                  )}
                />
              ) : null}
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/Validation"
                  exact
                  render={(props) => (
                    <Validation
                      {...props}
                      isAuthed={this.props.auth.loggedIn}
                    />
                  )}
                />
              ) : null}
              <Route
                path="/Register"
                exact
                render={(props) => (
                  //@ts-ignore
                  <Order {...props} isAuthed={this.props.auth.loggedIn} />
                )}
              />
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/InviteLogin/:id"
                  exact
                  render={(props) => (
                    <InviteLogin
                      {...props}
                      isAuthed={this.props.auth.loggedIn}
                    />
                  )}
                />
              ) : null}
              <Route
                path="/Confirm/:userid/:code"
                render={(props) => <Confirm {...props} />}
              />
              {!this.props.auth.loggedIn ? (
                <Route
                  path="/Setup/:userid/:code"
                  render={(props) => <Setup {...props} />}
                />
              ) : null}
              {!this.props.auth.loggedIn ? (
                <Route path="/csla" exact component={NECLTC} />
              ) : null}
              <Route
                render={(props) => (
                  <Main
                    {...props}
                    isAuthed={this.props.auth.loggedIn}
                    show={this.state.show}
                    showHandler={this.toggleDrawer()}
                  />
                )}
              />
            </Switch>
          </div>
        </BrowserRouter>
        <div className="clearfix"></div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state: Props) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(App);
