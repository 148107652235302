import React from "react";
import { NavLink } from "react-router-dom";
import ceilogo from "../../../img/cei-logo-new.jpg";
import memspa from "../../../img/logo-memspa.jpg";
import yale from "../../../img/yale.png";
import sel4ma from "../../../img/logo-sel4ma.jpg";
import naesp from "../../../img/logo-naesp.jpg";
import tl from "../../../img/tl_logo.png";
import usdoe from "../../../img/logo-usdoe.jpg";

export default function NECLTC() {
  return (
    <div>
      <h3>Compassionate Schools Leadership Academy (CSLA)</h3>
      <div className="welcome">
        <p>
          To address equity, diversity, and mental health needs of students, the{" "}
          <b>Center for Educational Improvement</b> and <b>Yale University</b>{" "}
          are collaborating in a national project that involves:
        </p>
        <ul className="welcome-list">
          <li>
            A Feasibility Study in Year 1. Schools participating in the
            Feasibility Study will have access to S-CCATE and its reports at no
            charge. Visit S-CCATE's{" "}
            <NavLink to="/pricing">products and pricing</NavLink> for more
            information.
          </li>
          <li>
            A National Survey. Schools participating in the National Survey will
            be able to access S-CCATE at no cost between March and September 2023.
          </li>
          <li>
            National Research Project. Schools participating in our National
            Research Project between September 2023 and July 2024 will be able
            to access S-CCATE at no charge.
          </li>
        </ul>
        <p>
          When S-CCATE is completed by 7 or more staff at a school, your school
          will receive results, including the S-CCATE Action Guide with options
          for implementation to address areas of needs.
        </p>
        <p>
          If you have questions about eligibility or want to learn more about the CSLA, please see this{" "}
          <a href="https://www.youtube.com/watch?v=lp0VCKnGg7Q">video</a>
          {" "}
          from our recent information session.
          If you have further questions, please contact Dr. Martha Staeheli at {" "}
          <b>martha.staeheli@yale.edu</b>.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <img src={ceilogo} alt="CEI" style={{ width: "20%" }} />
          <img src={memspa} alt="MEMSPA" style={{ width: "20%" }} />
          <img src={yale} alt="Yale" style={{ width: "15%" }} />
          <img src={sel4ma} alt="SEL4MA" style={{ width: "20%" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <img src={tl} alt="TeachLink" style={{ width: "20%" }} />
          <img src={naesp} alt="naesp" style={{ width: "20%" }} />
          <img src={usdoe} alt="USDOE" style={{ width: "20%" }} />
        </div>
      </div>
    </div>
  );
}
