
import React from 'react';
import '../../App.css';
import tl from '../../lib/teachlink/TeachLinkAPI'
import StateDropdown from './StateDropdown'

const blank_area = {id:"", name:""}

export default class StateDistrictSchoolSelect extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            from_report: this.props.from_report || false,
            districts:[],
            schools:[],
            selectedState:this.props.selectedState,
            selectedDistrict:this.props.selectedDistrict,
            selectedSchool:this.props.selectedSchool,
        }
    }

    componentDidMount() {
        if(this.state.selectedState) {
            tl.get_districts(this.state.selectedState).then((districts) => {
                this.setState({districts:districts})
                if(this.state.selectedDistrict)
                {
                    this.get_schools(this.state.selectedDistrict)
                }
            })
        }
    }

    state_changed = (ev) => {
        this.setState({districts:[], schools:[]})

        if(this.props.school_admin)
        {
            tl.get_admin_districts(ev.target.value).then((districts) => {
                this.setState({districts:districts})
            })
        }
        else
        {
            if(ev.target.value != "")
            {
                tl.get_districts(ev.target.value).then((districts) => {
                    this.setState({districts:districts})
                })
            }
        }

        if(ev.target.value == "")
        {
            this.props.setTargetState(blank_area)
            this.props.setTargetDistrict(blank_area)
            this.props.setTargetSchool(blank_area)
        }


        if(this.state.from_report == true )
        {
            if(ev.target.value == "")
            {
                this.props.setTargetState(blank_area)
                this.props.setTargetDistrict(blank_area)
                this.props.setTargetSchool(blank_area)
            }
            else
            {
                this.props.setTargetState({id:ev.target.value, name:ev.target.selectedOptions[0].text})
            }
        } 
        else
        {
            this.props.setTargetState({id:ev.target.value, name:ev.target.selectedOptions[0].text})
        }
    }

    get_schools = (district_id) => {

        if(this.props.school_admin)
        {
            tl.get_admin_schools(district_id).then((schools) => {
                this.setState({schools:schools})
            })
        }
        else
        {
            if(district_id != "")
            {
                tl.get_schools(district_id).then((schools) => {
                    this.setState({schools:schools})
                })
            }
        }
    }

    district_changed = (ev) => {
        this.setState({schools:[]})

        if(ev.target.value == "other")
        {
            this.props.setTargetDistrict({name:"other"})
            return;
        }

        this.props.setTargetSchool(blank_area)

        this.get_schools(ev.target.value)

        if(this.state.from_report == true)
        {
            if(ev.target.value == "")
            {
                this.props.setTargetDistrict(blank_area)
                this.props.setTargetSchool(blank_area)
            }
            else
            {
                this.props.setTargetDistrict({id:ev.target.value, name:ev.target.selectedOptions[0].text})
            }
        }
        else
        {
            this.props.setTargetDistrict({id:ev.target.value, name:ev.target.selectedOptions[0].text})
        }
    }

    school_changed = (ev) => {
        this.setState({staff:null})

        if(ev.target.value == "other")
        {
            this.props.setTargetSchool({name:"other"})
            return;
        }

        if(this.state.from_report == true )
        {
            if(ev.target.value == "")
            {
                this.props.setTargetSchool(blank_area)
            }
            else
            {
                this.props.setTargetSchool({id:ev.target.value, name:ev.target.selectedOptions[0].text})
            }
        }  
        else
        {
            this.props.setTargetSchool({id:ev.target.value, name:ev.target.selectedOptions[0].text})
        }
    }

    render() {
        var district_select, school_select;

        district_select = (
            <select defaultValue={this.state.selectedDistrict} className="select_dropdown" onChange={(ev) => this.district_changed(ev, ev.target.value)}>
                <option value="">{this.state.from_report ? "All districts" : "Select a district.."}</option>
                {this.props.allowOther && <option value="other">Other (not listed)</option>}
                {this.state.districts.sort((a,b) => (a.name.trim() > b.name.trim()) ? 1 : -1).map((district) => {
                    return <option key={district.id} value={district.id}>{district.name}</option>
                })}
            </select>
        )

        school_select = (
            <select defaultValue={this.state.selectedSchool} className="select_dropdown" onChange={(ev) => this.school_changed(ev, ev.target.value)}>
                <option value="">{this.state.from_report ? "All schools" : "Select a school.."}</option>
                {this.props.allowOther && <option value="other">Other (not listed)</option>}
                {this.state.schools.sort((a,b) => (a.name.trim() > b.name.trim()) ? 1 : -1).map((school) => {
                    return <option key={school.id} value={school.id}>{school.name}</option>
                })}
            </select>
        )
        return (
            <div className="state-district-select">
                <StateDropdown allowCombine={this.props.allowCombine} value={this.state.selectedState} onChange={(ev) => this.state_changed(ev, ev.target.value)} defaultLabel={this.state.from_report} />
                {district_select}
                {school_select}
            </div>
        );
    }
}