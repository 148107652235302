import React, { Component } from 'react'
import colors from '../../lib/helpers/colors'
import { BarChart, PieChart } from 'react-chartkick'
import 'chart.js'

const factors = ["Factor 1: Leadership and a Compassionate School Community", "Factor 2: Conscious Awareness of Emotions and Stress", "Factor 3: Courage & Resiliency", "Factor 4: Confidence & Positivity", "Factor 5: Understanding of Equity"]

const RoleBreakdown = (props) => {

    const data = props.data;

    const rolesData = []

    var teacherPercent = 0;
    var teacherPointsFactor1 = 0;
    var teacherPointsFactor2 = 0;
    var teacherPointsFactor3 = 0;
    var teacherPointsFactor4 = 0;
    var teacherPointsFactor5 = 0;

    var adminPercent = 0;
    var adminPointsFactor1 = 0;
    var adminPointsFactor2 = 0;
    var adminPointsFactor3 = 0;
    var adminPointsFactor4 = 0;
    var adminPointsFactor5 = 0;

    var councilorPercent = 0;
    var councilorPointsFactor1 = 0;
    var councilorPointsFactor2 = 0;
    var councilorPointsFactor3 = 0;
    var councilorPointsFactor4 = 0;
    var councilorPointsFactor5 = 0;

    var breakdownArray = [];

    data[0].positionResults.forEach(res => {
        if (res.label.includes("Teacher")) {
            teacherPercent += Number(res.percent)
        } else if (res.label.includes("Administration")) {
            adminPercent += Number(res.percent)
        } else if (res.label.includes("School Mental Health Provider")) {
            console.log("mental health provider detected")
            councilorPercent += Number(res.percent)
        }
    })


    data[0].positionResults.forEach(res => {
        if (res.label.includes("Teacher")) {
            teacherPointsFactor1 += (Number(res.averages["factor1Average"]) * (res.percent/teacherPercent))
            teacherPointsFactor2 += (Number(res.averages["factor2Average"]) * (res.percent/teacherPercent))
            teacherPointsFactor3 += (Number(res.averages["factor3Average"]) * (res.percent/teacherPercent))
            teacherPointsFactor4 += (Number(res.averages["factor4Average"]) * (res.percent/teacherPercent))
            teacherPointsFactor5 += (Number(res.averages["factor5Average"]) * (res.percent/teacherPercent))
        } else if (res.label.includes("Administration")) {
            adminPointsFactor1 += (Number(res.averages["factor1Average"]) * (res.percent/adminPercent))
            adminPointsFactor2 += (Number(res.averages["factor2Average"]) * (res.percent/adminPercent))
            adminPointsFactor3 += (Number(res.averages["factor3Average"]) * (res.percent/adminPercent))
            adminPointsFactor4 += (Number(res.averages["factor4Average"]) * (res.percent/adminPercent))
            adminPointsFactor5 += (Number(res.averages["factor5Average"]) * (res.percent/adminPercent))
        } else if (res.label.includes("School Mental Health Provider")) {
            console.log("calculating factor averages")
            councilorPointsFactor1 += (Number(res.averages["factor1Average"]) * (res.percent/councilorPercent))
            councilorPointsFactor2 += (Number(res.averages["factor2Average"]) * (res.percent/councilorPercent))
            councilorPointsFactor3 += (Number(res.averages["factor3Average"]) * (res.percent/councilorPercent))
            councilorPointsFactor4 += (Number(res.averages["factor4Average"]) * (res.percent/councilorPercent))
            councilorPointsFactor5 += (Number(res.averages["factor5Average"]) * (res.percent/councilorPercent))
        }
    })

    const teacherData = {
        name: "Teacher",
        data: {
            "Factor 1: Leadership and a Compassionate School Community": teacherPointsFactor1,
            "Factor 2: Conscious Awareness of Emotions and Stress": teacherPointsFactor2,
            "Factor 3: Courage & Resiliency": teacherPointsFactor3,
            "Factor 4: Confidence & Positivity": teacherPointsFactor4,
            "Factor 5: Understanding of Equity": teacherPointsFactor5
        }
    }

    const adminData = {
        name: "Administrator",
        data: {
            "Factor 1: Leadership and a Compassionate School Community":adminPointsFactor1,
            "Factor 2: Conscious Awareness of Emotions and Stress":adminPointsFactor2,
            "Factor 3: Courage & Resiliency":adminPointsFactor3,
            "Factor 4: Confidence & Positivity":adminPointsFactor4,
            "Factor 5: Understanding of Equity":adminPointsFactor5,
        }
    }

    const councilorData = {
        name: "School Mental Health Provider",
        data: {
            "Factor 1: Leadership and a Compassionate School Community":councilorPointsFactor1,
            "Factor 2: Conscious Awareness of Emotions and Stress":councilorPointsFactor2,
            "Factor 3: Courage & Resiliency":councilorPointsFactor3,
            "Factor 4: Confidence & Positivity":councilorPointsFactor4,
            "Factor 5: Understanding of Equity":councilorPointsFactor5
        }
    }

    if (teacherPercent > 0) {
        rolesData.push(teacherData)
        breakdownArray.push(["Teacher (" + Number(teacherPercent*100).toFixed(1)+"%)", teacherPercent*100])
    }
    if (adminPercent > 0) {
        rolesData.push(adminData)
        breakdownArray.push(["Administrator (" + Number(adminPercent*100).toFixed(1)+"%)", adminPercent*100])
    }
    if (councilorPercent > 0) {
        rolesData.push(councilorData)
        breakdownArray.push(["School Meantal Health Provider (" + Number(councilorPercent*100).toFixed(1)+"%)", councilorPercent*100])
    }

    data[0].positionResults.forEach(res => {
        var points = {}

        factors.forEach((factor,i) => {
            points[factor] = res.averages["factor"+(i+1)+"Average"]
        })

        if(res.percent > 0 && !res.label.includes("/"))
        {
            rolesData.push({
                name:res.label,
                data:points
            })
        }
    })

    var breakdownData = data[0].positionResults.filter(p => p.percent > 0 && !p.label.includes("/")).map(x => [x.label + " (" + Number(x.percent*100).toFixed(1)+"%)", x.percent*100])
    breakdownData = [...breakdownArray, ...breakdownData];
    return (
        <div className="role-breakdown">
            {!props.hidePie && <div id="role-pie">
                <PieChart width={800} height={400} donut={true} data={breakdownData} colors={colors.comparison_colors} title="Position Breakdown" />
            </div>}
            <div id="role-factor-breakdown">
                <BarChart id="component_factor_graph" width={800} height={450} colors={colors.comparison_colors} data={rolesData} max={4} title={"Factor Averages by Position"} library={{
                            scales: {
                            yAxes: [
                                {
                                    ticks: { autoSkip:false }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: { autoSkip:false }   
                                }
                            ]
                            }
                        }}/>
            </div>
        </div>
    )
}

export default RoleBreakdown;