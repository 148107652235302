import React from 'react';
import logo from '../../../img/logo.png'
import poster from '../../../img/poster.png'
import { Button } from '@mui/material';
import { Link } from "react-router-dom";
import manual from "../../../pdf/manual.pdf"

export default function Validation(props) {
    return (
        <div>
            <h3>Validation Study and Manual</h3>
            <div className="panel large-pad margin-bt">
                <h4 style={{marginTop:0}}>Validation Study and How to Use S-CCATE</h4>
 
                Information on both the S-CCATE validation study and also on how to use S-CCATE, including information on the S-CCATE Supplement, may be found in the <a href={manual} target="_blank">S-CCATE manual</a>. With S-CCATE Basic, you receive up to three reports with graphs and national comparison data. For more specific suggestions regarding implementation, including suggested professional development and interventions, check out our Action Guides. The Action Guides will also provide you information that is useful for School Improvement Plans and developing annual reports for state assessments and ESSA.
                <br /><br />
                <a href={manual} className="btn btn-primary" target="_blank">Download S-CCATE Manual</a>
            </div>
        </div>
    )
}