import React from 'react';
import '../../App.css';
import tl from '../../lib/teachlink/TeachLinkAPI';
import Question from '../surveys/Question';
import { Button } from '@mui/material';
import Loading from './Loading';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import GenericSnackbar from '../snackbar/GenericSnackbar';

var responseRequired

export default class Survey extends React.Component {

  constructor(props)
  {
    super(props)
    
    this.state = {
      loading: true,
      survey:null,
      pages: [[]],
      current_page:0,
      showNumberQuestionsDialog:false
    }
  }

  questionAnswered = (element, answer, options) => {
    if(element.elementType === 0 || element.elementType === 1)
    {
      return options && options.length > 0;
    }
    return answer && String(answer).trim().length > 0
  }

  onAnswerChanged = (element, answer, options, explain_answer) => {
    if(this.props.onAnswerChanged)
    {
      this.props.onAnswerChanged(element, answer, options, explain_answer)
    }
  }

  requiredQuestionsAnswered = () => {
    var pages = this.state.pages
    var found_empty_required = false

    pages[this.state.current_page].filter(x => x.is_required).forEach((question) => {
      if(this.props.response)
      {
        var answer = this.props.response.answers.find(x => x.element_id == question.id)
        if(!answer || !this.questionAnswered(question, answer.answer, answer.selected_option_ids))
        {
          found_empty_required = true
          question.is_incomplete = true
        }
        else
        {
          question.is_incomplete = false
        }
      }
    })
    this.setState({pages:pages})
    if(found_empty_required === false)
    {
      return true;
    }
    else
    {
      return false;
    }
    
  }

  getAllElements = () => {
    return this.state.pages.flat(1)
  }

  numberOfLeveledQuestionsAnsweredGte = (limit) => {

    var answered_count = 0;

    this.getAllElements().filter(x => x.elementType == 4).forEach((question) => {
      if(this.props.response)
      {
        var answer = this.props.response.answers.find(x => x.element_id == question.id)
        if(answer && this.questionAnswered(question, answer.answer, answer.selected_option_ids))
        {
          answered_count++;
        }
      }
    })
  
    return answered_count >= limit;  
  }

  mapPages = (survey) => {
    var pages = [[]];
    var page_num = 0;

    survey.elements.forEach((element) => {
      if(element.elementType === 5)
      {
        page_num++;
        pages[page_num] = []
      }
      else
      {
        pages[page_num].push(element)
      }
    })
    return pages;
  }
  
  componentDidMount() {
    responseRequired = <div></div>
    tl.get_survey(this.props.id, this.props.trial, this.props.code).then((survey) => {
      this.props.surveyTitle(survey.name)
      this.setState({pages:this.mapPages(survey), survey:survey, loading:false})},
    ).catch((err) => {
        console.log(err)
    })
  }

  go_to_next_page() {
    if(this.requiredQuestionsAnswered()) 
    {
      if(this.state.current_page !== this.state.pages.length-1)
      {
        this.setState({current_page:Math.min(this.state.pages.length-1, this.state.current_page+1)})
        window.scrollTo(0, 0)
        responseRequired = <div></div>
      }
      else
      {
        this.setState({showNumberQuestionsDialog:false})

        if(this.numberOfLeveledQuestionsAnsweredGte(10) || this.props.trial)
        {
          if(this.props.submitSurvey)
          {
            this.props.submitSurvey()
          }
        }
        else
        {
          this.setState({showNumberQuestionsDialog:true})
        }
      }
    }
    else
    {
      responseRequired = <div className="incomplete_title">
                            <AppBar position="static" color="default">
                              <Toolbar>
                                <Typography variant="h6" color="inherit">
                                  One or more required questions have been left unanswered
                                </Typography>
                              </Toolbar>
                            </AppBar>
                          </div>
    }
  }

  go_to_prev_page() {
    responseRequired = <div></div>
    this.setState({current_page:Math.max(0, this.state.current_page-1)})
  }

  render() {  
    if(this.state.loading)
    {
      return (<Loading />)
    }

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const mobileView = screenHeight > screenWidth;

    return (
      <div className="survey-questions">
        {this.state.current_page === 0 && <div>
          <div className={mobileView ? "welcome-title-mobile" : "welcome-title"}><h3 style={{marginTop:0}}>Welcome to the Center for Educational Improvement’s (CEI) School Compassionate Culture Analytical Tool for Educators</h3></div>
          <div className={mobileView ? "welcome-message-mobile" : "welcome-message"}>
The S-CCATE Assessment is uniquely designed for schools to guide teams of educators and whole school communities through the process of compassionate transformational change.
            <h4>Terminology and Rating Scale</h4>
When determining the rating, please select the number below which reflects your opinion about each S-CCATE statement.
            <ul>
                <li>4: Strongly Agree</li>
                <li>3: Agree</li>
                <li>2: Disagree</li>
                <li>1: Strongly Disagree</li>
                <li>Don't Know - Reserve a reating of "Don't Know" for only those instances where you have no knowledge of the statement</li>
            </ul>
            
            <h4>Terminology</h4>
            
            <strong>Equity</strong> refers to policies and procedures that are fair and just.

            <br />
            <br />

            The term “school leadership” refers to <strong>building level administrators</strong>, and may include Principals, Assistant Principals, Deans of Students, or in some charter or private schools, Executive Directors, or other heads of school.

            <br />      
            <br />

            If you would like more information <a href="/" target="_blank">click here</a>.
            <br />
            <br />
Within the S-CCATE, please rate the degree to which you agree or disagree with the following statements:
          </div>
          <br />
          {this.props.trial && <h4 style={{color:'#c466c5', fontSize:20}}>This is a trial. Any data entered as a trial will not be saved or included in any school reports.</h4>}
          <hr />
        </div>}
        {this.state.pages.map((page, i) => {
            if(i === this.state.current_page)
            {
              return (
                <div key={i} className="page">
                  {responseRequired}
                  {page.map((question, j) => {
                      return (
                        <Question incomplete={question.is_incomplete} from_survey={this.props.coming_from_view ? false : true} response={this.props.response} key={j} questions={page} question={question} index={j} onAnswerChanged={(answer, options, explain_answer) => this.onAnswerChanged(question, answer, options, explain_answer)} />
                      )
                  })}
                  {responseRequired}
                  <div className="page-nav">
                    <Button variant="contained" disabled={this.state.current_page === 0} color="primary" onClick={() => this.go_to_prev_page()}>Prev Page</Button>
                    <Button className="right" variant="contained" color="primary" onClick={() => this.go_to_next_page()}>{this.state.current_page !== this.state.pages.length-1 ? "Next Page" : "Submit"}</Button>
                    <div className="clearfix"></div>
                  </div>
                </div>
              )
            }
        })}
      <GenericSnackbar open={this.state.showNumberQuestionsDialog} onClose={() => this.setState({showNumberQuestionsDialog:false})} message={"You must answer at least 10 questions out of the 40 about your school’s culture."} variant="error" />
      </div>
    );
  }
}
