import React from "react";
import "../../App.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { useState } from "react";

export default class Question extends React.Component {
  constructor(props) {
    super(props);
    var question_id = props.question.id;
    if (this.props.from_survey === true) {
      var answer = props.response.answers.find((x) =>
        x.element_id == question_id
      );
    }

    this.state = {
      answer: answer
        ? answer.answer
        : (props.question.elementType === 2 || props.question.elementType === 3
          ? ""
          : -1),
      checked_options: answer
        ? answer.selected_option_ids.map((x) => x.optionId)
        : [],
      explain_answer: answer ? answer.explain_answer : "",
      showTeacherRoles: false,
      showAdminRoles: false,
      showMentalHealthRoles: false,
    };
  }

  onAnswerChanged = (answer, options, explain_answer) => {
    if (this.props.onAnswerChanged) {
      this.props.onAnswerChanged(answer, options, explain_answer);
    }
  };

  handleOptionChange = (changeEvent) => {
    var checked_options = [changeEvent.target.value];
    this.setState({ checked_options: checked_options });
    this.onAnswerChanged(null, checked_options, null);
  };

  handleSelectMultipleChange = (changeEvent) => {
    var checked_options = this.state.checked_options.filter((x) =>
      x != changeEvent.target.value
    );
    if (changeEvent.target.checked) {
      checked_options.push(changeEvent.target.value);
    }
    this.setState({ checked_options: checked_options });
    this.onAnswerChanged(null, checked_options, null);
  };

  handleAnswerChange = (answer) => {
    this.setState({ answer: answer });
    this.onAnswerChanged(answer, null, null);
  };

  handleExpainAnswerChange = (answer) => {
    this.setState({ explain_answer: answer });
    this.onAnswerChanged(null, this.state.checked_options, answer);
  };

  getLevelIndex = () => {
    if (this.props.question.elementType != 4) {
      return -1;
    }
    if (this.props.index === 0) {
      return 0;
    }
    var idx = this.props.index;
    var levelIndex = 0;

    while (idx > 0 && this.props.questions[idx - 1].elementType == 4) {
      levelIndex++;
      idx--;
    }
    return levelIndex;
  };

  optionChecked = (option_id) => {
    if (this.state.checked_options.find((x) => x == option_id)) {
      return true;
    }
    return false;
  };

  setShowTeacherRoles = () => {
    this.setState({
      showTeacherRoles: true,
      showAdminRoles: false,
      showMentalHealthRoles: false,
    });
  };

  setShowAdminRoles = () => {
    this.setState({
      showTeacherRoles: false,
      showAdminRoles: true,
      showMentalHealthRoles: false,
    });
  };

  setShowMentalHealthRoles = () => {
    this.setState({
      showTeacherRoles: false,
      showAdminRoles: false,
      showMentalHealthRoles: true,
    });
  };

  render() {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const mobileView = screenHeight > screenWidth;

    var requiredText = this.props.question.is_required ? "*" : null;
    var questionText = (
      <span className="question-text">
        {requiredText} {this.props.question.text}
      </span>
    );

    // Hard coding this is bad, but adding a new element type would take a long time and I don't think this
    // style of question needs to be reusable
    // Come back and replace this text comparison with an id comparison
    if (
      this.props.question.text ===
      "Which of the following most closely describes your current role?"
    ) {
      // Create an array for each sub role
      const teacherRoles = [];
      const adminRoles = [];
      const mentalHealthRoles = [];
      const nonRole = [];

      // Go over all options and drop into sub-roles
      this.props.question.options.forEach((option) => {
        if (option.label.includes("Teacher")) {
          teacherRoles.push(option);
        } else if (option.label.includes("Administration")) {
          adminRoles.push(option);
        } else if (option.label.includes("Mental Health Provider")) {
          mentalHealthRoles.push(option);
        } else {
          nonRole.push(option);
        }
      });

      return (
        <div className={this.props.incomplete === true ? "incomplete" : ""}>
          <div className={"mobile-question-container"}>
            <div className={"mobile-question multiple-choice"}>
              {questionText}
              <div className={"mobile-question multiple-choice select"}>
                <RadioGroup>
                  <FormControlLabel
                    onChange={this.setShowTeacherRoles}
                    checked={this.state.showTeacherRoles}
                    name={"teacher"}
                    value={"teacher"}
                    control={<Radio color="primary" />}
                    label={"Teacher"}
                  />
                  <div className="sub-question">
                    {this.state.showTeacherRoles && (
                      teacherRoles.map((option) => {
                        return (
                          <div key={option.id} className="mobile-mc-option-sub">
                            <FormControlLabel
                              onChange={this.handleOptionChange}
                              checked={this.optionChecked(option.id)}
                              name={this.props.question.id}
                              value={option.id}
                              control={<Radio color="primary" />}
                              label={option.label.substring(8)}
                            />
                            {option.hasMoreInfo && this.optionChecked(option.id)
                              ? (
                                <textarea
                                  onChange={(ev) =>
                                    this.setState({
                                      explain_answer: ev.target.value,
                                    })}
                                  onBlur={() =>
                                    this.handleExpainAnswerChange(
                                      this.state.explain_answer,
                                    )}
                                  value={this.state.explain_answer}
                                />
                              )
                              : ""}
                          </div>
                        );
                      })
                    )}
                  </div>
                  <FormControlLabel
                    onChange={this.setShowAdminRoles}
                    checked={this.state.showAdminRoles}
                    name={"admin"}
                    value={"admin"}
                    control={<Radio color="primary" />}
                    label={"Administrator"}
                  />
                  <div className="sub-question">
                    {this.state.showAdminRoles && (
                      adminRoles.map((option) => {
                        return (
                          <div key={option.id} className="mobile-mc-option">
                            <FormControlLabel
                              onChange={this.handleOptionChange}
                              checked={this.optionChecked(option.id)}
                              name={this.props.question.id}
                              value={option.id}
                              control={<Radio color="primary" />}
                              label={option.label.substring(15)}
                            />
                            {option.hasMoreInfo && this.optionChecked(option.id)
                              ? (
                                <textarea
                                  onChange={(ev) =>
                                    this.setState({
                                      explain_answer: ev.target.value,
                                    })}
                                  onBlur={() =>
                                    this.handleExpainAnswerChange(
                                      this.state.explain_answer,
                                    )}
                                  value={this.state.explain_answer}
                                />
                              )
                              : ""}
                          </div>
                        );
                      })
                    )}
                  </div>
                  <FormControlLabel
                    onChange={this.setShowMentalHealthRoles}
                    checked={this.state.showMentalHealthRoles}
                    name={"mentalHealth"}
                    value={"mentalHealth"}
                    control={<Radio color="primary" />}
                    label={"Mental Health Provider"}
                  />
                  <div className="sub-question">
                    {this.state.showMentalHealthRoles && (
                      mentalHealthRoles.map((option) => {
                        return (
                          <div key={option.id} className="mobile-mc-option">
                            <FormControlLabel
                              onChange={this.handleOptionChange}
                              checked={this.optionChecked(option.id)}
                              name={this.props.question.id}
                              value={option.id}
                              control={<Radio color="primary" />}
                              label={option.label.substring(30)}
                            />
                            {option.hasMoreInfo && this.optionChecked(option.id)
                              ? (
                                <textarea
                                  onChange={(ev) =>
                                    this.setState({
                                      explain_answer: ev.target.value,
                                    })}
                                  onBlur={() =>
                                    this.handleExpainAnswerChange(
                                      this.state.explain_answer,
                                    )}
                                  value={this.state.explain_answer}
                                />
                              )
                              : ""}
                          </div>
                        );
                      })
                    )}
                  </div>
                  {nonRole.map((option) => {
                    return (
                      <div key={option.id} className="mobile-mc-option">
                        <FormControlLabel
                          onChange={this.handleOptionChange}
                          checked={this.optionChecked(option.id)}
                          name={this.props.question.id}
                          value={option.id}
                          control={<Radio color="primary" />}
                          label={option.label}
                        />
                        {option.hasMoreInfo && this.optionChecked(option.id)
                          ? (
                            <textarea
                              onChange={(ev) =>
                                this.setState({
                                  explain_answer: ev.target.value,
                                })}
                              onBlur={() =>
                                this.handleExpainAnswerChange(
                                  this.state.explain_answer,
                                )}
                              value={this.state.explain_answer}
                            />
                          )
                          : ""}
                      </div>
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    }

    if (mobileView) {
      if (this.props.question.elementType === 0) {
        return (
          <div className={this.props.incomplete === true ? "incomplete" : ""}>
            <div className={"mobile-question-container"}>
              <div className={"mobile-question multiple-choice"}>
                {questionText}
                <div className={"mobile-question multiple-choice select"}>
                  <RadioGroup>
                    {this.props.question.options.map((option) => {
                      return (
                        <div key={option.id} className="mobile-mc-option">
                          <FormControlLabel
                            onChange={this.handleOptionChange}
                            checked={this.optionChecked(option.id)}
                            name={this.props.question.id}
                            value={option.id}
                            control={<Radio color="primary" />}
                            label={option.label}
                          />
                          {option.hasMoreInfo && this.optionChecked(option.id)
                            ? (
                              <textarea
                                onChange={(ev) =>
                                  this.setState({
                                    explain_answer: ev.target.value,
                                  })}
                                onBlur={() =>
                                  this.handleExpainAnswerChange(
                                    this.state.explain_answer,
                                  )}
                                value={this.state.explain_answer}
                              />
                            )
                            : ""}
                        </div>
                      );
                    })}
                  </RadioGroup>
                </div>
              </div>
            </div>
            <hr />
          </div>
        );
      }
      if (this.props.question.elementType === 1) {
        return (
          <div>
            {questionText} <br />
            {this.props.question.options.map((option) => {
              return (
                <div key={option.id}>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          onChange={this.handleSelectMultipleChange}
                          name={option.label}
                          value={option.id}
                          checked={this.optionChecked(option.id)}
                        />
                      }
                      label={option.label}
                    />
                  </div>
                  {option.hasMoreInfo && this.optionChecked(option.id)
                    ? (
                      <textarea
                        onChange={(ev) =>
                          this.setState({ explain_answer: ev.target.value })}
                        onBlur={() =>
                          this.handleExpainAnswerChange(
                            this.state.explain_answer,
                          )}
                        value={this.state.explain_answer}
                      />
                    )
                    : ""}
                </div>
              );
            })}
            <hr />
          </div>
        );
      }
      if (this.props.question.elementType === 2) {
        // if (this.props.question.text === "School Name") {
        //     console.log("returning school select")
        //     return (
        //         <SchoolSelect />
        //     )
        // }
        return (
          <div className={this.props.incomplete === true ? "incomplete" : ""}>
            <div className="question text-mobile">
              {questionText}
              <br />
              <input
                type="text"
                onChange={(ev) =>
                  this.setState({ answer: ev.target.value })}
                onBlur={() => this.handleAnswerChange(this.state.answer)}
                value={this.state.answer}
              />
            </div>
          </div>
        );
      }
      if (this.props.question.elementType === 3) {
        return (
          <div className={this.props.incomplete === true ? "incomplete" : ""}>
            <div className="question textarea">
              {questionText}
              <br />
              <textarea
                onChange={(ev) =>
                  this.setState({ answer: ev.target.value })}
                onBlur={() => this.handleAnswerChange(this.state.answer)}
                value={this.state.answer}
              />
            </div>
          </div>
        );
      }
      if (this.props.question.elementType === 4) {
        return (
          <div>
            <div className="question-text">
              {this.props.question.text}
              {requiredText}
            </div>
            <RadioGroup>
              <FormControlLabel
                value="strongDisagree"
                control={
                  <Radio color="primary" checked={this.state.answer == 1} />
                }
                onChange={() => this.handleAnswerChange(1)}
                label="Strongly Disagree"
              />
              <FormControlLabel
                value="disagree"
                control={
                  <Radio color="primary" checked={this.state.answer == 2} />
                }
                onChange={() => this.handleAnswerChange(2)}
                label="Disagree"
              />
              <FormControlLabel
                value="agree"
                control={
                  <Radio color="primary" checked={this.state.answer == 3} />
                }
                onChange={() => this.handleAnswerChange(3)}
                label="Agree"
              />
              <FormControlLabel
                value="stronglyAgree"
                control={
                  <Radio color="primary" checked={this.state.answer == 4} />
                }
                onChange={() => this.handleAnswerChange(4)}
                label="Strongly Agree"
              />
              <FormControlLabel
                value="dontKnow"
                control={
                  <Radio color="primary" checked={this.state.answer == 0} />
                }
                onChange={() => this.handleAnswerChange(0)}
                label="Don't Know"
              />
            </RadioGroup>
            <hr />
          </div>
        );
      }
    }

    if (this.props.question.elementType === 0) {
      return (
        <div className={this.props.incomplete === true ? "incomplete" : ""}>
          <div className="question multiple-choice">
            {questionText}
            <br />
            {this.props.question.options.map((option) => {
              return (
                <div key={option.id} className="mc-option">
                  <div>
                    <input
                      type="radio"
                      onChange={this.handleOptionChange}
                      name={this.props.question.id}
                      value={option.id}
                      checked={this.optionChecked(option.id)}
                    />{" "}
                    {option.label}
                  </div>
                  {option.hasMoreInfo && this.optionChecked(option.id)
                    ? (
                      <textarea
                        onChange={(ev) =>
                          this.setState({ explain_answer: ev.target.value })}
                        onBlur={() =>
                          this.handleExpainAnswerChange(
                            this.state.explain_answer,
                          )}
                        value={this.state.explain_answer}
                      />
                    )
                    : ""}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (this.props.question.elementType === 1) {
      return (
        <div className={this.props.incomplete === true ? "incomplete" : ""}>
          <div className="question multiple-select">
            {questionText}
            <br />
            {this.props.question.options.map((option) => {
              return (
                <div key={option.id} className="mc-option">
                  <div>
                    <input
                      type="checkbox"
                      onChange={this.handleSelectMultipleChange}
                      name={option.label}
                      value={option.id}
                      checked={this.optionChecked(option.id)}
                    />{" "}
                    {option.label}
                  </div>
                  {option.hasMoreInfo && this.optionChecked(option.id)
                    ? (
                      <textarea
                        onChange={(ev) =>
                          this.setState({ explain_answer: ev.target.value })}
                        onBlur={() =>
                          this.handleExpainAnswerChange(
                            this.state.explain_answer,
                          )}
                        value={this.state.explain_answer}
                      />
                    )
                    : ""}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (this.props.question.elementType === 2) {
      return (
        <div className={this.props.incomplete === true ? "incomplete" : ""}>
          <div className="question text">
            {questionText}
            <br />
            <input
              type="text"
              onChange={(ev) =>
                this.setState({ answer: ev.target.value })}
              onBlur={() => this.handleAnswerChange(this.state.answer)}
              value={this.state.answer}
            />
          </div>
        </div>
      );
    }
    if (this.props.question.elementType === 3) {
      return (
        <div className={this.props.incomplete === true ? "incomplete" : ""}>
          <div className="question textarea">
            {questionText}
            <br />
            <textarea
              onChange={(ev) =>
                this.setState({ answer: ev.target.value })}
              onBlur={() => this.handleAnswerChange(this.state.answer)}
              value={this.state.answer}
            />
          </div>
        </div>
      );
    }
    if (this.props.question.elementType === 4) {
      var levelHeaders;

      if (this.getLevelIndex() % 5 === 0) {
        levelHeaders = (
          <div className="level-row head">
            <div className="level-col"></div>
            <div className="level-col">Strongly Disagree</div>
            <div className="level-col">Disagree</div>
            <div className="level-col">Agree</div>
            <div className="level-col">Strongly Agree</div>
            <div className="level-col">Don't Know</div>
          </div>
        );
      }
      return (
        <div className="question level">
          {levelHeaders}
          <div className={this.props.incomplete === true ? "incomplete" : ""}>
            <div className="level-row">
              <div className="level-col">
                {this.props.question.text} {requiredText}
              </div>
              <div className="level-col">
                <input
                  type="radio"
                  checked={this.state.answer == 1}
                  name={this.props.question.id}
                  onChange={() => this.handleAnswerChange(1)}
                />
              </div>
              <div className="level-col">
                <input
                  type="radio"
                  checked={this.state.answer == 2}
                  name={this.props.question.id}
                  onChange={() => this.handleAnswerChange(2)}
                />
              </div>
              <div className="level-col">
                <input
                  type="radio"
                  checked={this.state.answer == 3}
                  name={this.props.question.id}
                  onChange={() => this.handleAnswerChange(3)}
                />
              </div>
              <div className="level-col">
                <input
                  type="radio"
                  checked={this.state.answer == 4}
                  name={this.props.question.id}
                  onChange={() => this.handleAnswerChange(4)}
                />
              </div>
              <div className="level-col">
                <input
                  type="radio"
                  checked={this.state.answer == 0}
                  name={this.props.question.id}
                  onChange={() => this.handleAnswerChange(0)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.props.question.elementType === 5) {
      return (
        <div className="question page-break">
          --- Page Break ---
        </div>
      );
    }

    return <div className="question"></div>;
  }
}
