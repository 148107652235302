import React, { useState, useEffect } from 'react';
import tl from '../../lib/teachlink/TeachLinkAPI'
import Loading from '../general/Loading';

import '../../App.css';
import "react-datepicker/dist/react-datepicker.css";

export default function Confirm(props) {
    const [loading, setLoading] = useState(true);
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
        tl.confirm_email(props.match.params.userid, props.match.params.code).then(() => {
            setLoading(false)
        }).catch(() => {
            setInvalid(true)
            setLoading(false)
        })
    }, [])

    if(loading)
    {
        return <Loading />
    }

    return (
        <div style={{textAlign:"center", marginTop:80}}>
            {invalid ?
                <h3>Invalid Confirmation Code, You May Have To Resend</h3>
                :
                <h3>Email Confirmed Successfully</h3>
            }
            <a href="/Login">Click Here</a> to login
        </div>
    )
}