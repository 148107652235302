import React from 'react';
import '../../App.css';
import { connect } from 'react-redux'
import tl from '../../lib/teachlink/TeachLinkAPI'
import Button from '@mui/material/Button';
import logo from '../../img/logo.png'
import Loading from '../general/Loading'
import { Redirect } from "react-router-dom";

class Login extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            email:this.props.signupLogin ? this.props.match.params.email : this.props.email,
            password:"",
            confirm_password:"",
            first_name:"",
            last_name:"",
            login_err:null,
            loading:false,
            register:this.props.register
        }
    }

    loginRegister = (ev) => {
        ev.preventDefault();
        this.setState({login_err:null, loading:true})
        if(this.state.register)
        {
          tl.register_user(this.state.email, this.state.first_name, this.state.last_name, this.state.password, this.state.confirm_password, this.props.invite_key).then(() => {
            this.setState({register:false, password:"", confirm_password:"", loading:false})
          }).catch((err) => {
            this.setState({login_err:err.message, loading:false})
          })
        }
        else
        {
          tl.login_user(this.state.email, this.state.password).then(() => {
            this.setState({login_err:null})
          }).catch((err) => {
            this.setState({login_err:err.message, loading:false})
          })
        }
    }

    render() {
        if(this.state.loading && !this.props.isAuthed)
        {
          return <Loading />
        }

        if(this.props.isAuthed && this.props.signupLogin)
        {
          return <Redirect to={"/TrialVersion/"+tl.get_sccate_survey_id()} />
        }

        if(this.props.isAuthed)
        {
          return <Redirect to="/" />
        }
        
        return (
            <div className="login">
              <div className="inner">
                  <img src={logo} className="login-logo" alt="logo" />
                  <br />
                  {this.props.signupLogin && "Thank you for registering! Please login with the account you just created"}
                  <form onSubmit={this.loginRegister}>
                  {this.state.register ?
                        (
                          <div>
                            <input type="text" required name="first_name" placeholder="first name" value={this.state.first_name} onChange={(ev) => this.setState({first_name:ev.target.value})} />
                            <br />
                            <input type="text" required name="last_name" placeholder="last name" value={this.state.last_name} onChange={(ev) => this.setState({last_name:ev.target.value})} />
                            <br />
                          </div>
                        ) : null
                    }
                    <input type="email" name="email" disabled={this.props.email != null} placeholder="email" value={this.state.email} onChange={(ev) => this.setState({email:ev.target.value})} />
                    <br />
                    <input type="password" required name="password" placeholder="password" value={this.state.password} onChange={(ev) => this.setState({password:ev.target.value})} />
                    <br />
                    {this.state.register ?
                        (
                          <div>
                            <input type="password" required name="confirm_password" placeholder="confirm password" value={this.state.confirm_password} onChange={(ev) => this.setState({confirm_password:ev.target.value})} />
                            <br />
                          </div>
                        ) : null
                    }
                    <Button type="submit" variant="contained" color="primary">
                      {!this.state.register ? "Login" : "Register"}
                    </Button>
                    {!this.props.email && !this.state.register ? (
                        <div className="login-swap">
                          <br />
                          {!this.props.signupLogin && (
                            <>
                              Don't have an account yet? <a href="/Register">Register</a>
                              <br /><br />
                            </>
                          )}
                          Forgot your password? <a href="https://app.myteachlink.com/User/account/ForgotPassword" target="_blank">Reset Password</a><br />
                          <em style={{color:'#aaa'}}>(Note: Will take you to TeachLink to reset)</em>
                        </div>
                      ) : 
                      !this.props.email && !this.props.signupLogin && this.state.register ? (
                        <div className="login-swap">
                          <br />
                          Already have an account? <a href="/Login">Login</a>
                        </div>
                      )
                      :null
                    }
                  </form>
                  <div className={this.state.login_err ? "error" : "error hidden"}>
                    {Array.isArray(this.state.login_err) ? this.state.login_err[0] : this.state.login_err}
                  </div>
                </div>
            </div>
          )
    }
}

export default connect()(Login)
