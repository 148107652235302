import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/logo.png";
import sampleQuestions from "../../img/sample-questions.jpg";
import purpose from "../../img/purpose.jpg";
import data from "../../img/data.jpg";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import manual from "../../pdf/manual.pdf";

export default function Welcome(props) {

  // If the screen is taller than wide, show mobile view
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const mobileView = screenHeight > screenWidth;

  const download = () => {
    fetch(manual).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "S-CCATEManual.pdf";
        alink.click();
      });
    });
  };

  return (
    <div>
      <div className="welcome">
        <div className="welcome-center">
          <img src={logo} className="welcome-logo" />
        </div>
        <div className="welcome-title">
          <h3>
            Welcome to the Center for Educational Improvement’s (CEI) School
          

          Compassionate Culture Analytical Tool for Educators
          </h3>
        </div>
        <div className="welcome-message welcome-left">
          S-CCATE assessments are uniquely designed for schools to guide teams
          of educators and whole school communities through the process of
          compassionate transformational change.
          <br />
          <br />
          As you complete the S-CCATE survey, answer by rating each item with
          reference to your learning environment over the past several months,
          with consideration of both virtual and in-class instruction.
          {
            mobileView ? (
              <div className="welcome-center">
                <div className="welcome-title" style={{ marginBottom: "0px", fontSize: "18px" }}>
                  <h3>User Survey Instructions</h3>
                </div>
                <div className='wrap'>
                  <iframe className="frame" width="568" height="315" src="https://www.youtube.com/embed/AUG__kMdZKc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="welcome-title" style={{ marginBottom: "0px", fontSize: "18px" }}>
                  <h3>Administrator School Sign Up Instructions</h3>
                </div>
                <div className='wrap'>
                  <iframe className="frame" width="568" height="315" src="https://www.youtube.com/embed/AUG__kMdZKc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            ) : (
              <div className="welcome-row">
                <div className="welcome-column">
                  <div
                    className="welcome-title "
                    style={{ marginBottom: "0px", fontSize: "18px" }}
                  >
                    <h3>User Survey Instructions</h3>
                  </div>
                  <iframe
                    width="90%"
                    height="300"
                    src="https://www.youtube.com/embed/AUG__kMdZKc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
                <div className="welcome-column">
                  <div
                    className="welcome-title"
                    style={{ marginBottom: "0px", fontSize: "18px" }}
                  >
                    <h3>School Sign Up Instructions</h3>
                  </div>
                  <iframe
                    width="90%"
                    height="300"
                    src="https://www.youtube.com/embed/iZ-oGi9SG3k"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </div>
            )
          }
          <br />
          <br />
          <div className="welcome-column">
            <p>If your school has purchased S-CCATE</p>
            {!props.isAuthed ? (
              <a href="/Login">
                <Button
                  variant="contained"
                  color="primary"
                  className="welcome-login"
                >
                  Click Here To Login
                </Button>
              </a>
            ) : (
              <div>
                {props.userCanManage ? (
                  <div>
                    <Link to="/Reports">
                      <Button
                        variant="contained"
                        className="welcome-manage-link"
                      >
                        Click here to manage S-CCATE
                      </Button>
                    </Link>
                  </div>
                ) : null}
                <Link to="/Surveys">
                  <Button
                    variant="contained"
                    color="primary"
                    className="welcome-login"
                  >
                    Click here to take the survey
                  </Button>
                </Link>
              </div>
            )}
            <p>If your school has not purchased S-CCATE</p>
            <a href="/Pricing">
              <Button
                variant="contained"
                color="secondary"
                className="welcome-login"
              >
                {props.isAuthed ? "View Pricing Info" : "Get Started For Free"}
              </Button>
            </a>
          </div>
          <br />
          <br />
          <p style={{ fontSize: 18 }}>
            <h3>User Survey Instructions</h3>
          </p>
          <div className="welcome-center">
            <img
              src={sampleQuestions}
              style={{ maxWidth: "50%", border: "1px solid #eee" }}
            />
          </div>
          <br />
          <div>
            <h3 style={{ color: "#006ac5" }}>Purpose and Use of S-CCATE</h3>
          </div>
          <p>
            Use the S-CCATE for an initial assessment to guide discussion with
            others about school status, visions, planning, and implementation of
            plans for developing schools as Heart Centered Learning&reg;
            communities. If you are interested in participating in the{" "}
            <b>Compassionate Schools Leadership Academy (CSLA)</b> feasibility
            study, more information can be found{" "}
            <NavLink to="/csla">here</NavLink>.
          </p>
          <div className="welcome-center">
            <img src={purpose} style={{ maxWidth: "50%" }} />
            <br />
            <img src={data} style={{ maxWidth: "50%" }} />
          </div>
          <div style={{ textAlign: "center" }}>
            <a className="btn btn-primary" onClick={download}>
              Download S-CCATE Manual
            </a>
          </div>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              textAlign: "center",
            }}
          >
            Please contact Dr. Martha Staeheli (martha.staeheli@yale.edu) with any questions or for
            more information.
          </p>
        </div>
      </div>
    </div>
  );
}
