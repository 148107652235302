import React, { Component } from 'react'
import colors from '../../lib/helpers/colors'
import { BarChart, PieChart } from 'react-chartkick'
import 'chart.js'

const factors = ["Factor 1", "Factor 2", "Factor 3", "Factor 4", "Factor 5"]

const GenderBreakdown = (props) => {

    const data = props.data;

    const raceData = []

    data[0].genderLevelResults.forEach(res => {
        var points = {}

        factors.forEach((factor,i) => {
             points[factor] = res.averages["factor"+(i+1)+"Average"]
        })

        if(res.percent > 0)
        {
            raceData.push({
                name:res.label,
                data:points
            })
        }
    })

    var breakdownData = data[0].genderLevelResults.filter(x => x.percent > 0).map(x => [x.label + " (" + Number(x.percent*100).toFixed(1)+"%)", x.percent*100])

    return (
        <div className="gender-breakdown">
            <div id="gender-pie">
                <PieChart width={800} height={400} donut={true} data={breakdownData} colors={colors.comparison_colors} width={800} height={400} title="Gender Breakdown" />
            </div>
            <div id="gender-bar">
                <BarChart id="component_factor_graph" width={800} height={450} colors={colors.comparison_colors} data={raceData} max={4} title={"Factor Averages by Gender"} library={{
                            scales: {
                            yAxes: [
                                {
                                    ticks: { autoSkip:false }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: { autoSkip:false }   
                                }
                            ]
                            }
                        }}/>
            </div>
        </div>
    )
}

export default GenderBreakdown;