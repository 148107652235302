import React, { Component } from 'react'
import colors from '../../lib/helpers/colors'
import { BarChart, PieChart } from 'react-chartkick'
import 'chart.js'

const factors = ["Factor 1", "Factor 2", "Factor 3", "Factor 4", "Factor 5"]

const EthnicityBreakdown = (props) => {

    const data = props.data;

    const ethnicityData = []

    data[0].ethnicityLevelResults.forEach((res, i) => {
        console.log("counting data")
        var points = {}

        factors.forEach((factor,i) => {
             points[factor] = res.averages["factor"+(i+1)+"Average"]
        })

        if(res.percent > 0)
        {
            ethnicityData.push({
                name:res.label,
                data:points
            })
        }
    })

    var breakdownData = data[0].ethnicityLevelResults.filter(x => x.percent > 0).map(x => [x.label + " (" + Number(x.percent*100).toFixed(1)+"%)", x.percent*100])

    console.log("breakdown data: ", breakdownData);

    return (
        <div className="ethnicity-breakdown">
            <div id="ethnicity-pie">
                <PieChart donut={true} width={800} height={400} data={breakdownData} colors={colors.comparison_colors} title="Ethnicity Breakdown" />
            </div>
            <div id="ethnicity-bar">
                <BarChart id="component_factor_graph" width={800} height={450} colors={colors.comparison_colors} data={ethnicityData} max={4} title={"Factor Averages by Ethnicity"} library={{
                            scales: {
                            yAxes: [
                                {
                                    ticks: { autoSkip:false }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: { autoSkip:false }   
                                }
                            ]
                            }
                        }}/>
            </div>
        </div>
    )
}

export default EthnicityBreakdown;