import React, { useState, useEffect } from 'react';
import tl from '../../lib/teachlink/TeachLinkAPI'

export default function StateDropdown(props) {

    const [states, setStates] = useState([])

    useEffect(() => {
      setStates(tl.get_state_list())
    }, [])

    if(states.length > 0)
    {
        return (
            <select defaultValue={props.value || ""} name="state" className="select_dropdown" onChange={props.onChange}>
                <option value="">{props.defaultLabel || (props.allowCombine ? "Choose a State or Region" : "Choose a State") }</option>
                    {props.allowCombine && <option key="CT,MA,ME,NH,RI,VT" value="CT,MA,ME,NH,RI,VT">New England (Region)</option>}
                    {props.allowCombine && <option key="AK,AL,AR,AS,AZ,CA,CO,DC,DE,FL,GA,GU,HI,IA,ID,IL,IN,KS,KY,LA,MD,MI,MN,MO,MP,MS,MT,NC,ND,NE,NJ,NM,NV,NY,OH,OK,OR,PA,PR,SC,SD,TN,TX,UM,UT,VA,VI,WA,WI,WV,WY" value="AK,AL,AR,AS,AZ,CA,CO,DC,DE,FL,GA,GU,HI,IA,ID,IL,IN,KS,KY,LA,MD,MI,MN,MO,MP,MS,MT,NC,ND,NE,NJ,NM,NV,NY,OH,OK,OR,PA,PR,SC,SD,TN,TX,UM,UT,VA,VI,WA,WI,WV,WY">Non-New England (Region)</option>}
                    {states.map((state) => {
                        return <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
                    })}
            </select>
        )
    }

    return null;
}