var states = require("../data/states_titlecase.json");

var remote = true;

// var staging =
//   remote &&
//   (window?.location?.href?.includes("staging1") ||
//     window?.location?.href?.includes("localhost"));

var base_url = "https://app.myteachlink.com/";

var default_survey_id = "22c552a1-1fee-4b53-8d65-b3bde9cf33ed";
// var default_survey_id = "983ca383-ce55-47e5-8a59-3d0f43ab5529";

if (!remote) {
  base_url = "http://localhost:63429/";
  // default_survey_id = "ddd8f26d-8d41-4890-a7e0-ad6d48414f57";
}

// if (staging) {
//   base_url = "https://teachlink-staging.azurewebsites.net/";
// }

var auth_state_callback = null;
var current_user = null;

function isDevMode() {
  return process.env.NODE_ENV && process.env.NODE_ENV === "development";
}

async function authorize(callback) {
  auth_state_callback = callback;
  const token = get_access_token();
  if (token != null) {
    try {
      const response = await fetch(base_url + "api/users/account/authtest", {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const body = await response.json();
        current_user = body;
        auth_state_callback(body);
      } else {
        auth_state_callback();
      }
    } catch (error) {
      auth_state_callback();
    }
  } else {
    auth_state_callback();
  }
}

//helper that will get replaced once we build multiple survey capabilities
//for sccate managers, right not just works with default
function get_default_survey_id() {
  return default_survey_id;
}

async function get_graph_data() {
  try {
    const response = await fetch(base_url + "api/sccate/surveydata/GetGraphData", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const body = await response.json();
      return body;
    } else {
      throw new Error('Request failed');
    }
  } catch (error) {
    throw error;
  }
}

async function merge_pdfs(json) {
  try {
    const response = await fetch("https://reports.s-ccate.org/merge-pdfs", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    });
    if (response.ok) {
      const body = await response.json();
      return body;
    } else {
      throw new Error('Request failed');
    }
  } catch (error) {
    throw error;
  }
}

function get_rec(factor, round) {
  return get_pdf(`Recs.Factor ${factor}.Round ${round}.pdf`);
}

function get_action_guide(factor, round) {
  return get_pdf(`AG.Factor ${factor}.Round ${round}.pdf`);
}

async function get_pdf(name) {
  try {
    const response = await fetch(
      remote
        ? `https://reports.s-ccate.org/recs/${name}?token=${get_access_token()}`
        : `http://localhost:3001/recs/${name}?token=${get_access_token()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.ok) {
      const body = await response.json();
      return body;
    } else {
      throw new Error('Request failed');
    }
  } catch (error) {
    throw error;
  }
}

async function get_table_data() {
  try {
    const response = await fetch(base_url + "api/sccate/surveydata/GetTableData", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const body = await response.json();
      return body;
    } else {
      throw new Error('Request failed');
    }
  } catch (error) {
    throw error;
  }
}

async function login_user(email, password) {
  const formData = new URLSearchParams();
  formData.append('grant_type', 'password');
  formData.append('username', email);
  formData.append('password', password);

  try {
    const response = await fetch(base_url + "token", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    });
    if (response.ok) {
      const body = await response.json();
      localStorage.setItem("username", body.userName);
      localStorage.setItem("access_token", body.access_token);
      if (auth_state_callback != null) {
        authorize(auth_state_callback);
      }
      return body.userName;
    } else {
      const errorBody = await response.json();
      throw new Error(errorBody.error_description || "An error has occurred, please try again later");
    }
  } catch (error) {
    throw error;
  }
}

function register_user(
  email,
  first_name,
  last_name,
  password,
  confirm_password,
  invite_key
) {
  if (!first_name || first_name.trim().length == 0) {
    return Promise.reject("First Name Is Required");
  }
  if (!last_name || last_name.trim().length == 0) {
    return Promise.reject("Last Name Is Required");
  }
  if (!email || email.trim().length == 0) {
    return Promise.reject("Email Is Required");
  }
  if (!password || password.trim().length == 0) {
    return Promise.reject("Password Is Required");
  }
  if (!confirm_password || confirm_password.trim().length == 0) {
    return Promise.reject("Please Confirm Password");
  }
  if (confirm_password !== password) {
    return Promise.reject("Passwords Do Not Match");
  }
  return unauthorized_post(base_url + "api/users/account/registeruser", {
    Email: email,
    Password: password,
    ConfirmPassword: confirm_password,
    FirstName: first_name,
    LastName: last_name,
    InviteKey: invite_key,
  });
}

function confirm_email(user_id, code) {
  return unauthorized_post(
    base_url +
    "api/users/account/confirmemail?userId=" +
    user_id +
    "&code=" +
    code
  );
}

function send_order(
  first_name,
  last_name,
  email,
  password,
  confirm_password,
  credentials,
  jobtitle,
  workphone,
  role,
  districtname,
  schoolname,
  otherdistrictname,
  otherschoolname,
  schoolstate
) {
  if (confirm_password !== password) {
    return Promise.reject("Passwords Do Not Match");
  }
  return unauthorized_post(base_url + "api/users/account/RegisterOrder", {
    firstname: first_name,
    lastname: last_name,
    email: email,
    password: password,
    credentials: credentials,
    jobtitle: jobtitle,
    workphonenumber: workphone,
    role: role,
    districtname: districtname,
    schoolname: schoolname,
    otherdistrictname: otherdistrictname,
    otherschoolname: otherschoolname,
    schoolstate: schoolstate,
  });
}

function resend_confirmation_email() {
  return authorized_post(
    base_url + "/api/users/account/resendconfirmationemail"
  );
}

function create_survey(name) {
  return authorized_post(base_url + "api/sccate/surveydata/createsurvey", {
    name: name,
  });
}

function delete_survey(survey_id) {
  return authorized_post(
    base_url + "api/sccate/surveydata/deletesurvey",
    survey_id
  );
}

function create_question(survey_id, question) {
  return authorized_post(base_url + "api/sccate/surveydata/createquestion", {
    survey_id: survey_id,
    question: question,
  });
}

function update_question(survey_id, question) {
  return authorized_post(base_url + "api/sccate/surveydata/updatequestion", {
    survey_id: survey_id,
    question: question,
  });
}

function add_district(state, name) {
  return authorized_post(base_url + "api/sccate/general/createdistrict", {
    state: state,
    districtName: name,
  });
}

function add_school(districtId, name) {
  return authorized_post(base_url + "api/sccate/general/createschool", {
    district_id: districtId,
    school: { name: name },
  });
}

function update_order(surveyID, id_array, order_array) {
  return authorized_post(base_url + "api/sccate/surveydata/orderquestions", {
    survey_id: surveyID,
    questions_id: id_array,
    questions_weight: order_array,
  });
}

function save_answer(
  response_id,
  element_id,
  survey_id,
  answer,
  explain_answer,
  selected_option_ids_csv
) {
  return authorized_post(base_url + "api/sccate/surveyresponse/saveanswer", {
    survey_id: survey_id,
    response_id: response_id,
    answer: answer,
    element_id: element_id,
    selected_option_ids_csv: selected_option_ids_csv,
    explain_answer: explain_answer,
  });
}

function finalize_response(response_id) {
  return authorized_post(
    base_url + "api/sccate/surveyresponse/finalizeresponse",
    response_id
  );
}

function save_answer_anon(
  response_id,
  element_id,
  survey_id,
  answer,
  explain_answer,
  selected_option_ids_csv
) {
  return unauthorized_post(base_url + "api/sccate/surveyresponse/saveanswer", {
    survey_id: survey_id,
    response_id: response_id,
    answer: answer,
    element_id: element_id,
    selected_option_ids_csv: selected_option_ids_csv,
    explain_answer: explain_answer,
  });
}

function finalize_response_anon(response_id) {
  return unauthorized_post(
    base_url + "api/sccate/surveyresponse/finalizeresponse",
    response_id
  );
}

function accept_manager_invite(invite_id) {
  return authorized_post(
    base_url + "api/sccate/surveyinvites/acceptmanagerinvite",
    invite_id
  );
}

function delete_invite(invite_id) {
  return authorized_post(
    base_url + "api/sccate/surveyinvites/deleteinvite",
    invite_id
  );
}

function delete_question(survey_id, question) {
  return authorized_post(base_url + "api/sccate/surveydata/deletequestion", {
    survey_id: survey_id,
    question: question,
  });
}

function send_invites(invites, scheduled_at) {
  var send_invites = [];
  Object.values(invites).forEach((school) => {
    Object.values(school.members).forEach((member) => {
      let copied = Object.assign({}, member);
      copied.scheduledAt = scheduled_at;
      send_invites.push(copied);
    });
  });
  return authorized_post(base_url + "api/sccate/surveyinvites/inviteusers", {
    invites: send_invites,
  });
}

function get_surveys() {
  return authorized_get(base_url + "api/sccate/surveydata/surveys");
}

function get_survey_code_for_school(schoolId, surveyId, type, academic_year) {
  return authorized_get(
    base_url +
    `api/sccate/surveycodes/GetSurveyCodeForSchool?schoolId=${schoolId}&surveyId=${surveyId}&type=${type}&academicYear=${academic_year}`
  );
}

function create_survey_code_for_school(schoolId, surveyId, type, academicYear) {
  return authorized_post(
    base_url + "api/sccate/surveycodes/CreateSurveyLinkForSchool",
    {
      schoolId: schoolId,
      surveyId: surveyId,
      type: type,
      academicYear: academicYear,
    }
  );
}

function get_survey_invites() {
  return authorized_get(base_url + "api/sccate/surveyinvites/myinvites");
}

function get_manager_invites() {
  return authorized_get(base_url + "api/sccate/surveyinvites/mymanagerinvites");
}

function get_schools_with_responses() {
  return authorized_get(
    base_url + "api/sccate/reportdata/getschoolswithreponses"
  );
}

function get_all_schools() {
  return authorized_get(base_url + "api/generalapi/getallschools");
}

function get_invite_stats(school_id, type, academic_year) {
  return authorized_get(
    base_url +
    "api/sccate/reportdata/getinvitestats?schoolId=" +
    school_id +
    "&type=" +
    type +
    "&academicYear=" +
    academic_year
  );
}

function request_report(school_id, type) {
  return authorized_post(
    base_url +
    "api/sccate/reportdata/RequestReport?schoolId=" +
    school_id +
    "&type=" +
    type,
    {}
  );
}

function get_invite_user(id) {
  return unauthorized_get(
    base_url + "api/sccate/surveyinvites/getinviteuser?id=" + id
  );
}

function get_survey_responses() {
  return authorized_get(base_url + "api/sccate/surveyresponse/getmyresponses");
}

function get_school_by_id(id) {
  return authorized_get(
    base_url + "api/generalapi/getschoolbyid?schoolId=" + id
  );
}

function get_responses_in_range(survey_id, start_date, end_date, include_unfinished = true) {
  return authorized_get(
    base_url +
    "api/sccate/reportdata/getresponsesinrange?survey_id=" +
    survey_id +
    "&start=" +
    start_date +
    "&end=" +
    end_date +
    "&include_unfinished=" +
    include_unfinished
  );
}

function get_response_count_data(
  survey_id,
  start_date,
  end_date,
  state_ab,
  district_id,
  school_id,
  academic_year,
  type
) {
  return authorized_get(
    base_url +
    "api/sccate/reportdata/getresponsecountdata?survey_id=" +
    survey_id +
    "&start=" +
    start_date +
    "&end=" +
    end_date +
    "&state_ab=" +
    state_ab +
    "&district_id=" +
    district_id +
    "&school_id=" +
    school_id +
    "&academic_year=" +
    academic_year +
    "&type=" +
    type
  );
}

function get_survey_response(response_id) {
  return authorized_get(
    base_url + "api/sccate/surveyresponse/response?response_id=" + response_id
  );
}

function get_survey_response_with_code(response_id, code) {
  return unauthorized_get(
    base_url +
    `api/sccate/surveyresponse/coderesponse?response_id=${response_id}&code=${code}`
  );
}

function start_survey(inviteId) {
  return authorized_post(base_url + "api/sccate/surveyresponse/startsurvey", {
    invite_id: inviteId,
  });
}

function start_survey_with_code(code) {
  return unauthorized_post(
    base_url + "api/sccate/surveyresponse/startsurveywithcode",
    { code: code }
  );
}

function get_districts(state) {
  return unauthorized_get(
    base_url + "api/generalapi/getdistricts?state=" + state
  );
}

function get_schools(district_id) {
  return unauthorized_get(
    base_url + "api/generalapi/getschools?districtId=" + district_id
  );
}

function get_staff(school_id) {
  return authorized_get(
    base_url + "api/generalapi/getstaff?schoolId=" + school_id
  );
}

function get_sccate_staff(school_id, type, academic_year) {
  return authorized_get(
    base_url +
    "api/generalapi/getsccatestaff?schoolId=" +
    school_id +
    "&type=" +
    type +
    "&academicYear=" +
    academic_year
  );
}

function get_survey(survey_id, trial, code) {
  if (code) {
    return unauthorized_get(
      base_url +
      "api/sccate/surveydata/getsurvey?id=" +
      survey_id +
      "&code=" +
      code
    );
  }
  return authorized_get(
    base_url +
    "api/sccate/surveydata/get" +
    (trial ? "trial" : "") +
    "survey?id=" +
    survey_id
  );
}

function delete_report(report_id) {
  return authorized_post(
    base_url + "api/sccate/reportdata/DeleteReport?reportId=" + report_id
  );
}

function send_report_to_school(
  pdf,
  name,
  school_id,
  type,
  academic_year,
  survey_id
) {
  var token = get_access_token();
  if (token) {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("name", name);
      form.append("academicYear", academic_year);
      form.append("type", type);
      form.append("school_id", school_id);
      form.append("survey_id", survey_id);
      form.append("pdf", pdf);
      var rq = new XMLHttpRequest();
      rq.open("POST", base_url + "api/sccate/reportdata/AddReportResponse");
      rq.setRequestHeader("Authorization", "Bearer " + token);
      rq.send(form);
      rq.onload = function () {
        if (rq.status == 200) {
          resolve(rq.responseText);
        }
        reject();
      };
    });
  }
  return new Promise.reject("Invalid token, you may not be logged in");
}

function get_report_responses(school_id) {
  return authorized_get(
    base_url + "api/sccate/reportdata/getreportresponses?schoolId=" + school_id
  );
}

function get_sccate_survey_id() {
  return "22c552a1-1fee-4b53-8d65-b3bde9cf33ed"
}

function get_all_report_data(entityOne, entityTwo, academicYear, type) {
  const sendData = {
    surveyId: get_sccate_survey_id(),
    entityOne,
    entityTwo,
    academicYear: academicYear,
    type: type,
  };

  const test_data = [
    {
      "entity": {
        "id": "a09c833a-a65d-4852-969f-c566abd3e843",
        "name": "Panorama Middle School"
      },
      "academicYear": "all",
      "type": "all",
      "gradeLevelResults": [
        {
          "label": "Pre-K",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Kindergarten",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "1st ",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "2nd",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "3rd",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "4th",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "5th",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "6th",
          "percent": 0.66666666666666663,
          "averages": {
            "factor1Average": 2.4042553191489362,
            "factor2Average": 2.3076923076923075,
            "factor3Average": 2.275,
            "factor4Average": 2.84375,
            "factor5Average": 2.3644859813084111
          }
        },
        {
          "label": "7th",
          "percent": 0.5,
          "averages": {
            "factor1Average": 2.3043478260869565,
            "factor2Average": 2.2142857142857144,
            "factor3Average": 2.2666666666666666,
            "factor4Average": 2.4166666666666665,
            "factor5Average": 2.275
          }
        },
        {
          "label": "8th",
          "percent": 0.41666666666666669,
          "averages": {
            "factor1Average": 2.3157894736842106,
            "factor2Average": 2.2173913043478262,
            "factor3Average": 2.28,
            "factor4Average": 2.5,
            "factor5Average": 2.2727272727272729
          }
        },
        {
          "label": "9th",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "10th",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "11th",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "12th",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        }
      ],
      "ethnicityLevelResults": [
        {
          "label": "American Indian or Alaska Native",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Asian",
          "percent": 0.08333333,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Black or African American",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Hispanic or Latino",
          "percent": 0.083333333,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Middle Eastern or North African",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Native Hawaiian or Pacific Islander",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "White",
          "percent": 0.583333333,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Something else: ",
          "percent": 0.08333333,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Prefer not to answer",
          "percent": 0.1666667,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        }
      ],
      "genderLevelResults": [
        {
          "label": "Female/woman/girl",
          "percent": 0.545454,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Male/man/boy",
          "percent": 0.272727,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Nonbinary, genderqueer, or not exclusively female or male",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Transgender female/woman/girl",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Transgender male/man/boy",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Another gender:",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Don't know",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Prefer not to answer",
          "percent": 0.181818,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        }
      ],
      "positionResults": [
        {
          "label": "Teacher/General Education",
          "percent": 0.41666666666666669,
          "averages": {
            "factor1Average": 2.1166666666666667,
            "factor2Average": 2.0,
            "factor3Average": 1.88,
            "factor4Average": 2.45,
            "factor5Average": 2.1014492753623188
          }
        },
        {
          "label": "Teacher/Special Education",
          "percent": 0.33333333333333331,
          "averages": {
            "factor1Average": 2.4468085106382977,
            "factor2Average": 2.5,
            "factor3Average": 2.4,
            "factor4Average": 2.75,
            "factor5Average": 2.5094339622641511
          }
        },
        {
          "label": "Teacher/Specialist (e.g., Art, Physical Education, Music, etc.)",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Teacher/Instructional Aide",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Administration/Principal",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Administration/Assistant Principal",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Administration/Dean of Students",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Administration/Curriculum and Instruction Coordinator",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "School Mental Health Provider/Counselor",
          "percent": 0.083333333333333329,
          "averages": {
            "factor1Average": 2.2727272727272729,
            "factor2Average": 1.8,
            "factor3Average": 2.4,
            "factor4Average": 2.25,
            "factor5Average": 2.3076923076923075
          }
        },
        {
          "label": "School Mental Health Provider/Social Worker",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "School Mental Health Provider/School Psychologist",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Office Staff",
          "percent": 0.083333333333333329,
          "averages": {
            "factor1Average": 2.4166666666666665,
            "factor2Average": 2.5,
            "factor3Average": 2.4,
            "factor4Average": 3.0,
            "factor5Average": 2.3571428571428572
          }
        },
        {
          "label": "Support Staff (ex. bus driver, lunch aide, etc.) (Please Explain)",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        },
        {
          "label": "Other (Please explain.)",
          "percent": 0.0,
          "averages": {
            "factor1Average": 0.0,
            "factor2Average": 0.0,
            "factor3Average": 0.0,
            "factor4Average": 0.0,
            "factor5Average": 0.0
          }
        }
      ],
      "totalResponses": 12,
      "averages": {
        "factor1Average": 2.3115942028985508,
        "factor2Average": 2.2586206896551726,
        "factor3Average": 2.1896551724137931,
        "factor4Average": 2.6041666666666665,
        "factor5Average": 2.308176100628931
      }
    },
    {
      "entity": {
        "id": null,
        "name": "Harrison School District - D2"
      },
      "academicYear": "latest",
      "type": "latest",
      "gradeLevelResults": [],
      "ethnicityLevelResults": [],
      "genderLevelResults": [],
      "positionResults": [],
      "totalResponses": 68,
      "averages": {
        "factor1Average": 2.3344827586206898,
        "factor2Average": 2.1325301204819276,
        "factor3Average": 2.1422222222222222,
        "factor4Average": 2.7749077490774909,
        "factor5Average": 2.4002828854314004
      }
    },
    {
      "entity": {
        "name": "National"
      },
      "academicYear": null,
      "type": null,
      "gradeLevelResults": [],
      "ethnicityLevelResults": [],
      "genderLevelResults": [],
      "positionResults": [],
      "totalResponses": 3924,
      "averages": {
        "factor1Average": 2.54666454555096,
        "factor2Average": 2.2987519500780031,
        "factor3Average": 2.4020543981481484,
        "factor4Average": 2.8276009857494913,
        "factor5Average": 2.5023628874918709
      }
    }
  ]

  return authorized_post(
    base_url + "api/sccate/reportdata/GenerateReportData",
    sendData
  );
}

function get_report(report_id) {
  return authorized_get(
    base_url + "api/sccate/reportdata/getreport?reportId=" + report_id
  );
}

function get_optioned_responses_for_survey(
  survey_id,
  school_id,
  district_id,
  state_ab,
  academic_year,
  type
) {
  return authorized_get(
    base_url +
    "api/sccate/reportdata/GetOptionedResponsesForSurvey?survey_id=" +
    survey_id +
    "&school_id=" +
    school_id +
    "&district_id=" +
    district_id +
    "&state_ab=" +
    state_ab +
    "&academic_year=" +
    academic_year +
    "&type=" +
    type
  );
}

function get_optioned_responses_for_question(
  survey_id,
  school_id,
  district_id,
  state_ab,
  question_id,
  academic_year,
  type
) {
  return authorized_get(
    base_url +
    "api/sccate/reportdata/GetOptionedResponsesForQuestion?survey_id=" +
    survey_id +
    "&school_id=" +
    school_id +
    "&district_id=" +
    district_id +
    "&state_ab=" +
    state_ab +
    "&question_id=" +
    question_id +
    "&academic_year=" +
    academic_year +
    "&type=" +
    type
  );
}

function setup_district(districtName, districtState, schools) {
  const sendData = {
    districtName,
    districtState,
    schools,
  };
  return authorized_post(
    base_url + "api/sccate/general/SetupDistrict",
    sendData
  );
}

function get_all_admin_districts() {
  return authorized_get(base_url + "api/generalapi/GetAdminDistricts");
}

function get_admin_districts(state) {
  return authorized_get(
    base_url + "api/generalapi/GetAdminDistrictsInState?state_ab=" + state
  );
}

function get_admin_schools(district) {
  return authorized_get(
    base_url + "api/generalapi/GetAdminSchoolsInDistrict?districtId=" + district
  );
}

function get_all_admin_schools() {
  return authorized_get(base_url + "api/generalapi/GetSccateAdminSchools");
}

function get_district_by_id(district) {
  return authorized_get(
    base_url + "api/generalapi/GetDistrictById?districtId=" + district
  );
}

async function authorized_post(url, body) {
  const token = get_access_token();
  if (!token) {
    throw new Error("User not logged in");
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw new Error('Request failed');
  }
  return response.json();
}

async function authorized_get(url) {
  const token = get_access_token();
  if (!token) {
    throw new Error("User not logged in");
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Request failed');
  }
  return response.json();
}

async function unauthorized_get(url) {
  const response = await fetch(url, {
    method: 'GET',
  });
  if (!response.ok) {
    console.log('Request failed', response.body);
  }
  return response.json();
}

async function unauthorized_post(url, body) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw new Error('Request failed');
  }
  const responseText = await response.text();
  try {
    const responseJson = JSON.parse(responseText);
    return responseJson;
  } catch (error) {
    // JSON parse error on empty response body
    return;
  }

}

function clear_cached_auth() {
  localStorage.removeItem("username");
  localStorage.removeItem("access_token");
  current_user = null;
}

function logout_user() {
  clear_cached_auth();

  if (auth_state_callback != null) {
    auth_state_callback();
  }
}

function get_access_token() {
  return localStorage.getItem("access_token");
}

function user_is_restricted_sccate_admin() {
  var user = get_logged_in_user();
  if (user != null) {
    return user.roles.sccate_admin_restricted;
  }
  return false;
}

function user_is_sccate_admin() {
  var user = get_logged_in_user();
  if (user != null) {
    return user.roles.sccate_admin || user.roles.super_user;
  }
  return false;
}

function user_is_school_admin() {
  var user = get_logged_in_user();
  if (user != null) {
    return user.roles.school_admin;
  }
  return false;
}

function user_is_scccate_manager() {
  var user = get_logged_in_user();
  if (user != null) {
    return user.roles.sccate_manager;
  }
  return false;
}

function user_can_manage_school() {
  return user_is_school_admin() || user_is_scccate_manager();
}

function get_logged_in_user() {
  if (get_access_token() != null && current_user != null) {
    return current_user;
  } else {
    clear_cached_auth();
    return null;
  }
}

function get_state_list() {
  return states;
}

function get_response_count_public_survey(survey_id, element_id, zip_element_id) {
  return authorized_get(
    base_url + "api/sccate/reportdata/GetResponseCountsFromPublicSurvey?survey_id=" + survey_id + "&school_name_element=" + element_id + "&zip_code_element=" + zip_element_id
  )
}

function add_public_response_to_school(response_id, school_id, district_id, state) {
  return authorized_post(
    base_url + "api/sccate/reportdata/MoveSchools?response_id=" + response_id + "&school_id=" + school_id + "&district_id=" + district_id,
  )
}

module.exports = {
  authorize,
  get_all_admin_schools,
  get_all_admin_districts,
  get_admin_districts,
  get_admin_schools,
  get_district_by_id,
  get_survey_response_with_code,
  get_graph_data,
  get_table_data,
  login_user,
  logout_user,
  get_logged_in_user,
  get_surveys,
  create_survey,
  update_question,
  delete_question,
  get_survey,
  create_question,
  update_order,
  delete_survey,
  get_state_list,
  get_districts,
  get_schools,
  get_staff,
  send_invites,
  user_is_sccate_admin,
  user_is_school_admin,
  get_survey_invites,
  start_survey,
  get_survey_responses,
  get_survey_response,
  save_answer,
  finalize_response,
  get_optioned_responses_for_question,
  get_optioned_responses_for_survey,
  get_responses_in_range,
  get_response_count_data,
  get_invite_user,
  register_user,
  confirm_email,
  resend_confirmation_email,
  get_schools_with_responses,
  send_order,
  accept_manager_invite,
  user_is_scccate_manager,
  user_can_manage_school,
  get_default_survey_id,
  get_school_by_id,
  get_sccate_staff,
  delete_report,
  send_report_to_school,
  get_report_responses,
  base_url,
  get_report,
  get_invite_stats,
  delete_invite,
  get_manager_invites,
  setup_district,
  request_report,
  user_is_restricted_sccate_admin,
  add_district,
  add_school,
  get_rec,
  merge_pdfs,
  get_pdf,
  get_all_report_data,
  get_survey_code_for_school,
  create_survey_code_for_school,
  get_sccate_survey_id,
  start_survey_with_code,
  save_answer_anon,
  finalize_response_anon,
  get_all_schools,
  get_action_guide,
  get_response_count_public_survey,
  add_public_response_to_school,
};
