const defaultState = {
    loggedIn:false,
    user:null
}

const auth = (state = defaultState, action) => {
    switch (action.type) {
        case 'USER_LOGGED_IN':
            return {
                ...state,
                loggedIn:true,
                user:action.user
            }
        case 'USER_LOGGED_OUT':
            return {
                ...defaultState
            }
        default:
            return state
        }
  }
  
  export default auth