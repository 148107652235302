import React from "react";
import pricing from "../../../pdf/pricing.pdf";
import flyer from "../../../pdf/flyer.pdf";
import manual from "../../../pdf/manual.pdf";
import press from "../../../pdf/PressRelease.pdf";
import ceilogo from "../../../img/cei-logo-new.jpg";
import memspa from "../../../img/logo-memspa.jpg";
import yale from "../../../img/yale.png";
import sel4ma from "../../../img/logo-sel4ma.jpg";
import naesp from "../../../img/logo-naesp.jpg";
import tl from "../../../img/tl_logo.png";
import usdoe from "../../../img/logo-usdoe.jpg";

export default function More(props) {
  return (
    <div>
      <h3>General Information</h3>
      <div className="welcome">
        <h4 style={{ marginTop: 0 }}>Custom Packages</h4>
        For more information about custom packages contact
        s-ccate@edimprovement.org.
        <h4>Press Releases</h4>
        11/12/19 -{" "}
        <a href={press} target="_blank">
          Center for Educational Improvement Launches Online Technology Platform
          to Enhance Social Emotional Learning
        </a>
        <h4>About Us</h4>
        <b>About the Center for Educational Improvement.</b> The Center for
        Educational Improvement (CEI), in collaboration with Yale University
        Department of Psychiatry’s Program for Recovery and Community Health
        (Yale PRCH) is establishing a nationwide network of educational leaders
        trained in Compassionate School Practices (CSPs) through the
        Compassionate Schools Leadership Academy (CSLA). The CSLA will utilize
        the S-CCATE as a tool to help educators vision, plan, and monitor
        progress toward the implementation of compassionate, trauma-informed
        practices in classrooms. Additionally, the CSLA will work to modify the
        S-CCATE to improve cultural competence and equity. For more information
        about CEI, visit{" "}
        <a href="https://www.edimprovement.org" target="_blank">
          edimprovement.org
        </a>
        <br />
        <br />
        <b>About the Compassionate Schools Leadership Academy (CSLA)</b>. The
        Center for Educational Improvement (CEI), in collaboration with Yale
        University Department of Psychiatry’s Program for Recovery and Community
        Health (Yale PRCH) is establishing a nationwide network of educational
        leaders trained in Compassionate School Practices (CSPs) through the
        Compassionate Schools Leadership Academy (CSLA). The CSLA is using the
        S-CCATE as a tool to help educators envision, plan, and monitor progress
        toward the implementation of compassionate, trauma-informed practices in
        classrooms. Additionally, the CSLA will work to modify S-CCATE to
        improve cultural competence and equity. This project is funded by a
        three-year SEED grant from the U.S. Department of Education.
        <br />
        <br />
        <b>About the Childhood-Trauma Learning Collaborative (C-TLC).</b> From
        2018-2022, Yale University and the Center for Educational Improvement
        conducted the Childhood-Trauma Learning Collaborative (C-TLC). This was
        a joint project designed to strengthen mental health support that
        addresses the needs of children who have experienced or are at risk of
        experiencing significant trauma. The project provided technical
        assistance to implement school-based mental health services in the six
        states that are a part of Region 1 (Connecticut, Maine, Massachusetts,
        New Hampshire, Rhode Island, and Vermont). Funded under the Substance
        Abuse Mental Health Services Administration (SAMHSA), 24 fellows in
        these six states implemented individual projects using S-CCATE to
        facilitate decision-making and implementation of interventions to assist
        children with serious mental health and behavioral challenges.
        <br />
        <br />
        <a href="https://app.myteachlink.com/" target="_blank">
          myteachlink.com
        </a>{" "}
        or{" "}
        .<h4>The following resources are available for download</h4>
        <a href={manual} target="_blank">
          S-CCATE Manual
        </a>
        <br />
        <br />
        <a href={flyer} target="_blank">
          S-CCATE Promotional Flyer
        </a>
        <br />
        <br />
        <a href={pricing} target="_blank">
          S-CCATE Pricing Guide
        </a>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <img src={ceilogo} alt="CEI" style={{ width: "20%" }} />
          <img src={memspa} alt="MEMSPA" style={{ width: "20%" }} />
          <img src={yale} alt="Yale" style={{ width: "15%" }} />
          <img src={sel4ma} alt="SEL4MA" style={{ width: "20%" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <img src={tl} alt="TeachLink" style={{ width: "20%" }} />
          <img src={naesp} alt="naesp" style={{ width: "20%" }} />
          <img src={usdoe} alt="USDOE" style={{ width: "20%" }} />
        </div>
      </div>

      <div className="more-info">
        For more information, to order, or to discuss customized S-CCATE options
        for your customer group, contact the Center for Educational Improvement
        at info@edimprovement.org.
      </div>
    </div>
  );
}
