import React, { useState, useEffect } from 'react';
import tl from '../../lib/teachlink/TeachLinkAPI'
import exampleCsv from '../../lib/data/example_school_upload.csv'
import Loading from '../general/Loading';
import Button from '@mui/material/Button';
import '../../App.css';
import StateDropdown from '../general/StateDropdown'
import GenericSnackbar from '../snackbar/GenericSnackbar';
import ReactFileReader from 'react-file-reader';
const csvtojson = require("csvtojson");

const blankSchool = {
    name:"",
    address:"",
    city:"",
    zip:""
}

export default function Confirm(props) {
    const [loading, setLoading] = useState(true);
    const [invalid, setInvalid] = useState(false);
    const [asSchool, setAsSchool] = useState(true);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setloginError] = useState(null)
    const [state, setSchoolState] = useState(null)
    const [districtName, setDistrictName] = useState("")
    const [schools, setSchools] = useState([blankSchool])
    const [submitErrorMessage, setSubmitErrorMessage] = useState(null)
    const [setupFinished, setSetupFinished] = useState(false)
    const [managerSchools, setManagerSchools] = useState([])
    const [acceptDisclosureChecked, setAcceptDisclosureChecked] = useState(false)
    const [disclosureAccepted, setDisclosureAccepted] = useState(false)

    useEffect(() => {
        tl.confirm_email(props.match.params.userid, props.match.params.code).then(() => {
            if(!props.isAuthed) {
                setLoading(false)
            } else {
                tl.get_all_admin_schools().then((schools) => {
                    setManagerSchools(schools)
                    setLoading(false)
                }).catch(err => { console.log(err); })
            } 
        }).catch(() => {
            setInvalid(true)
            setLoading(false)
        })
    }, [])

    const login = (ev) => {
        ev.preventDefault();
        setLoading(true)
        tl.login_user(email, password).then(() => {
            tl.get_all_admin_schools().then((schools) => {
                setManagerSchools(schools)
                setloginError(null)
            }).catch(err => console.log(err))
        }).catch((err) => {
            setloginError(err)
            setLoading(false)
        })
    }

    const submit = () => {
        var status = validate()
        if(status.success)
        {
            tl.setup_district(districtName, state, schools).then(() => {
                window.location.href="/reports"
            }).catch((err) => {
                console.log(err)
            })
        }
        else
        {
            setSubmitErrorMessage(status.message)
        }
    }

    const validate = () => {
        var status = {
            success:true,
            message:"Success"
        }
        if(!districtName || districtName.trim() == "")
        {
            status.success = false;
            status.message="Please enter your district name"
            return status;
        }
        if(!state)
        {
            status.success = false;
            status.message = "Please choose a state for the district";
            return status;
        }
        if(schools.length == 0)
        {
            status.success = false;
            status.message = "You need to add at least one school";
            return status;
        }
        schools.forEach((school) => {
            if(!school.name || school.name.trim() == "")
            {
                status.success = false;
                status.message = "Please make sure all schools have names";
            }
            if(!school.address || school.address.trim() == "")
            {
                status.success = false;
                status.message = "Please make sure all schools have addresses";
            }
            if(!school.city || school.city.trim() == "")
            {
                status.success = false;
                status.message = "Please make sure all schools have a city entered";
            }
            if(!school.zip || school.zip.trim() == "")
            {
                status.success = false;
                status.message = "Please make sure all schools have zip codes";
            }
        })
        return status; 
    }

    const addSchool = () => {
        var _schools = [...schools]
        _schools.push({...blankSchool})
        setSchools(_schools)
    }

    const removeSchool = (i) => {
        var _schools = [...schools]
        _schools.splice(i,1)
        setSchools(_schools)
    }

    const updateName = (i, name) => {
        var _schools = [...schools]
        _schools[i].name = name
        setSchools(_schools)
    }

    const updateCity = (i, city) => {
        var _schools = [...schools]
        _schools[i].city = city
        setSchools(_schools)
    }

    const updateAddress = (i, address) => {
        var _schools = [...schools]
        _schools[i].address = address
        setSchools(_schools)
    }

    const updateZip = (i, zip) => {
        var _schools = [...schools]
        _schools[i].zip = zip
        setSchools(_schools)
    }
  
    function processData(evt) {
        var csv = evt.target.result
        csvtojson().fromString(csv).then((jsonObj) => {
            setSchools([...schools].concat(jsonObj))
            setLoading(false)
        }).catch(err => {
            setSubmitErrorMessage("Could not read CSV, please check format")
            setLoading(true)
        })
    }

    function errorHandler(evt) {
        setSubmitErrorMessage("Failed to read the CSV file")
        setLoading(false)
    }

    const insertCSV = (csv) => {
        if(csv && csv.length > 0)
        {
            setLoading(true)
            var reader = new FileReader();
            // Read file into memory as UTF-8      
            reader.readAsText(csv[0]);
            // Handle errors load
            reader.onload = processData;
            reader.onerror = errorHandler;
        }
    }

    if(loading)
    {
        return <Loading />
    }

    if(props.isAuthed)
    {
        if(tl.get_logged_in_user().id != props.match.params.userid)
        {
            return (
                <>
                    <h3>It appears as though you are signed in with another account.</h3>
                    <Button variant="contained" color="primary" onClick={() => tl.logout_user()}>Click Here Logout and Try Again</Button>
                </>
            )
        }
    }

    if(invalid)
    {
        return <h3>Sorry, we were not able to validate your sign up code. Please contact sales@s-ccate.org</h3>
    }

    var Login = (
        <>
            <b>Step 1:</b> Log into the account you just created.<br /><br />
            <input type="email" name="email" placeholder="email" value={email} onChange={(ev) => setEmail(ev.target.value)} />
            <br /><br />
            <input type="password" required name="password" placeholder="password" value={password} onChange={(ev) => setPassword(ev.target.value)} />
            <br /><br />
            <Button type="submit" variant="contained" color="primary" onClick={(ev) => login(ev)}>
                    Login
            </Button>
            <div className={loginError ? "error" : "error hidden"}>
                {Array.isArray(loginError) ? loginError[0] : loginError}
            </div>
            Forgot your password? <a href="https://app.myteachlink.com/User/account/ForgotPassword" target="_blank">Reset Password</a><br />
            <em style={{color:'#aaa'}}>(Note: Will take you to TeachLink to reset)</em>
        </>
    )

    var SchoolForm = (i, name, address, city, zip, state) => {
        return (
            <div className="school-signup" key={i}>
                <label>School Name:</label> <input type="text" value={name} onChange={(ev) => updateName(i, ev.target.value)}/><br />
                <label>Address:</label> <input type="text" value={address} onChange={(ev) => updateAddress(i, ev.target.value)}/><br />
                <label>City:</label> <input type="text" value={city} onChange={(ev) => updateCity(i, ev.target.value)}/><br />
                <label>State:</label> { state || "Not Selected"}<br />
                <label>Zip Code:</label> <input type="text" value={zip} onChange={(ev) => updateZip(i, ev.target.value)} /><br />
                {!asSchool && <Button variant="outlined" color="primary" className="remove-school" onClick={() => removeSchool(i)}>Remove School</Button>}
            </div>
        )
    }

    var SchoolSetup = (
         <div className="district-signup">
            <b>Step 2:</b> {asSchool ? "Set up your school" : "Set up your district"}.<br /><br />
            {!asSchool && <><a href={exampleCsv} target="_blank">Click here</a> for an example of the required CSV format if you wish to upload from a CSV.<br /><br /></>}
            <label>District Name:</label> <input type="text" name="districtName" value={districtName} onChange={(ev) => setDistrictName(ev.target.value)}/><br />
            <label>State:</label> <StateDropdown onChange={(ev) => setSchoolState(ev.target.value)} />
            {!asSchool ? (
                <>
                    <Button variant="contained" color="secondary" onClick={() => addSchool()}>Add Another School</Button>
                    <ReactFileReader fileTypes={[".csv"]} multipleFiles={false} handleFiles={insertCSV}>
                        <Button variant="outlined" color="secondary" className="upload-schools">Upload Schools From CSV</Button>
                    </ReactFileReader>
                </>
            ) : <br />}
            {schools.map((school, i) => {
                return SchoolForm(i, school.name, school.address, school.city, school.zip, state)
            })}
            <Button color="primary" variant="contained" className="save-setup-schools-button" onClick={() => submit()}>Save Schools and Complete Setup</Button>
        </div>
    )

    return (
        <>
            <h3>S-CCATE Setup - Thank you for signing up for S-CCATE!</h3>
            <div className="panel large-pad">
                {managerSchools.length > 0 ? (
                    <>
                        <h4 style={{marginTop:0}}>Looks like you have already completed the setup process!</h4>
                        Visit the <a href="/reports">Manage page</a> to invite additional staff members to take the survey, monitor survey progress, and request reports. If you would like to add additional schools, contact sales@s-ccate.org
                    </>
                ) :
                setupFinished ? (
                    <>
                        <h4 style={{marginTop:0}}>{asSchool ? "School" : "District"} setup finished!</h4>
                        Visit the <a href="/reports">Manage page</a> to invite additional staff members to take the survey, monitor survey progress, and request reports.
                    </>
                ) : (
                    <>
                        {!props.isAuthed ? Login : (
                            <>
                                <span className={asSchool ? "signup active" : "signup"} onClick={() => {setSchools([blankSchool]); setAsSchool(true);}}>Sign up your school</span>
                                <span className={!asSchool ? "signup active" : "signup"} onClick={() => setAsSchool(false)}>Sign up an entire district</span>
                                {!asSchool && !disclosureAccepted && (
                                    <div className="district-disclosure">
                                        Please note that only district-level employees (i.e. superintendent, district technology officer, etc.) can<br />sign up a district to complete the S-CCATE.<br /><br />
                                        <input type="checkbox" onClick={(ev) => setAcceptDisclosureChecked(ev.target.value)} /> I certify that I am a district-level employee and have data access rights in the district I am signing up.<br /><br />
                                        <button className="btn btn-primary" disabled={!acceptDisclosureChecked} onClick={() => setDisclosureAccepted(true)}>Accept and Continue</button>
                                    </div>
                                )}
                                {(asSchool || disclosureAccepted) && SchoolSetup}
                            </>
                        )}
                    </>
                )
                }  
            </div>
            <GenericSnackbar variant="error" message={submitErrorMessage} open={submitErrorMessage != null} onClose={() => setSubmitErrorMessage(null)} />
        </>
    )
}