import React, { useState, useEffect } from 'react';
import '../../App.css';
import tl from '../../lib/teachlink/TeachLinkAPI';
import FactorBar from '../graphs/FactorBar';
import GradeBreakdown from '../graphs/GradeBreakdown';
import EthnicityBreakdown from '../graphs/EthnicityBreakdown';
import RaceBreakdown from '../graphs/RaceBreakdown';
import RoleBreakdown from '../graphs/RoleBreakdown';
import GenderBreakdown from '../graphs/GenderBreakdown';

const SccateReportV2 = (props) => {
    const [data, setData] = useState("")
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {
        if(props.fullDataSet)
        {
            setData(props.fullDataSet)
            setLoadingData(false)
        }
    }, [props.fullDataSet])

    return (
        <div>
            <div id="graph" style={{position:'relative'}}>
                {loadingData ? (
                    <span>Loading....</span>
                ) : (
                    <div>
                        <FactorBar hasPrev={props.hasPrev} discrete={true} data={data} /><br /><hr /><br />
                        <GradeBreakdown data={data} /><br /><hr /><br />
                        <EthnicityBreakdown data={data} /><br /><hr /><br />
                        {/*<RaceBreakdown data={data} /><br /><hr /><br />*/}
                        <GenderBreakdown data={data} /><br /><hr /><br />
                        <RoleBreakdown data={data} />
                    </div>
                )}
            </div>
        </div>
    )
}

  export default SccateReportV2;