import React, { useState, useEffect } from "react";
import StateDropdown from "../../general/StateDropdown";
import tl from "../../../lib/teachlink/TeachLinkAPI";
import StateDistrictSchoolSelect from "../../general/StateDistrictSchoolSelect";
import Loading from "../../general/Loading";

export default function Order() {
  const [role, setRole] = useState("teacher");
  const [thanks, setThanks] = useState(false);
  const [setupError, setSetupError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [targetState, setTargetState] = useState("");
  const [targetDistrict, setTargetDistrict] = useState({ id: "", name: "" });
  const [targetSchool, setTargetSchool] = useState({ id: "", name: "" });

  const onSubmit = (ev) => {
    ev.preventDefault();
    setLoading(true);
    var email = ev.target.elements.email.value;
    tl.send_order(
      ev.target.elements.firstname.value,
      ev.target.elements.lastname.value,
      email,
      ev.target.elements.password.value,
      ev.target.elements.confirmpassword.value,
      ev.target.elements.credentials.value,
      ev.target.elements.jobtitle.value,
      ev.target.elements.workphone.value,
      ev.target.elements.role.value,
      targetDistrict.name,
      targetSchool.name,
      ev.target.elements.otherdistrictname?.value,
      ev.target.elements.otherschoolname?.value,
      targetState.name
    )
      .then(() => {
        if (role === "teacher") {
          window.location.href = "/SignupLogin/" + email;
        } else {
          setThanks(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSetupError(err);
      });
  };

  if (loading) {
    return <Loading />;
  }

  if (thanks) {
    return (
      <>
        <h3>S-CCATE Survey Sign Up</h3>
        <div className="panel large-pad">
          {targetState.name == "other" || targetDistrict.name == "other"
            ? "Thank you for signing up for the S-CCATE! Please check your e-mail (including your SPAM folder) for a validation e-mail with instructions to get started signing up your school or district. "
            : "Thank you for signing up for the S-CCATE! Please check your e-mail (including your SPAM folder) for a validation e-mail. An S-CCATE representative will contact you as soon as possible to get you added to the school you selected. "}
          For questions and concerns, including if you have not received a
          validation e-mail, please contact info@s-ccate.org.
        </div>
      </>
    );
  }

  var outputErr = setupError
    ? Array.isArray(setupError)
      ? setupError[0]
      : setupError
    : null;

  return (
    <div className="order">
      <h3>S-CCATE Survey Sign Up</h3>
      <div className="panel large-pad">
        <form onSubmit={onSubmit}>
          <b>
            Getting started with the S-CCATE assessment is FREE! You only pay
            when you decide to purchase the reports.
          </b>
          <br />
          <br />
          <i>Please fill out all of the fields below to get started.</i>
          <br />
          <i>
            Passwords must be at least 6 characters. Passwords must have at
            least one non letter or digit character, at least one lowercase
            letter, and at least one uppercase letter.
          </i>
          <br />
          <h4>Account Information</h4>
          <span className="label">Role: </span>
          <select name="role" onChange={(ev) => setRole(ev.target.value)}>
            <option value="teacher">Teacher: I want to take the survey</option>
            <option value="admin">
              Administrator or school leader: I want to sign up my school
            </option>
          </select>
          <br />
          <br />
          <span className="label">Email Address:</span>
          <input type="email" name="email" required />
          <br />
          <br />
          <span className="label">Password:</span>
          <input type="password" name="password" required />
          <br />
          <br />
          <span className="label">Confirm Password:</span>
          <input type="password" name="confirmpassword" required />
          <br />
          <br />
          <h4>Personal Information</h4>
          <span className="label">First Name:</span>
          <input type="text" name="firstname" required />
          <br />
          <br />
          <span className="label">Last Name:</span>
          <input type="text" name="lastname" required />
          <br />
          <br />
          <span className="label">Credentials, if applicable:</span>
          <input type="text" name="credentials" required defaultValue={"N/A"} />
          <br />
          <br />
          <span className="label">Job Title:</span>
          <input type="text" name="jobtitle" required />
          <br />
          <br />
          <span className="label">Select School:</span>
          <span className="school-signup-select">
            <StateDistrictSchoolSelect
              allowOther={true}
              setTargetState={(target_state) => setTargetState(target_state)}
              selectedState={targetState}
              setTargetDistrict={(target_district) => {
                setTargetDistrict(target_district);
              }}
              selectedDistrict={targetDistrict}
              setTargetSchool={(target_school) =>
                setTargetSchool(target_school)
              }
              selectedSchool={targetSchool}
            />
            <br />
          </span>
          {targetDistrict.name == "other" && (
            <>
              <span className="label">Enter District Name:</span>
              <input type="text" name="otherdistrictname" required />
              <br />
              <br />
            </>
          )}
          {(targetDistrict.name == "other" || targetSchool.name == "other") && (
            <>
              <span className="label">Enter School Name:</span>
              <input type="text" name="otherschoolname" required />
              <br />
              <br />
            </>
          )}
          <span className="label">Work Phone:</span>
          <input type="text" name="workphone" required />
          <br />
          <br />
          <div className={outputErr ? "error" : "error hidden"}>
            {outputErr &&
            outputErr.includes &&
            outputErr.includes("already taken")
              ? "Looks like you are already signed up for an account, either log in or contact sales@s-ccate.org to setup schools"
              : outputErr}
          </div>
          <br />
          <input
            type="submit"
            class="btn btn-primary"
            value={
              role == "teacher"
                ? "Take me to the survey"
                : "Sign up my school or district"
            }
          />
        </form>
      </div>
    </div>
  );
}
