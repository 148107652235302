import React from 'react';
import '../../App.css';
import ReactLoading from 'react-loading';

export default class Loading extends React.Component {
  render() {  
    return (
      <div className="loading">
        <ReactLoading type="bubbles" color="#2196f3" height={200} width={200} />
      </div>
    );
  }
}