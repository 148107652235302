import React, { Suspense, lazy } from "react";
import SidebarMenu from "./general/SidebarMenu";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Setup from "./auth/Setup";
import tl from "../lib/teachlink/TeachLinkAPI";
import SccateReportV2 from "./reports/SccateReportV2";
import Loading from "./general/Loading";

const Welcome = lazy(() => import("./pages/Welcome"));
const ListSurveys = lazy(() => import("./pages/ListSurveys"));
const ThankYou = lazy(() => import("./pages/static/ThankYou"));
const Account = lazy(() => import("./pages/Account"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ManageSurvey = lazy(() => import("./pages/ManageSurvey"));
const TakeSurvey = lazy(() => import("./pages/TakeSurvey"));
const SendSurvey = lazy(() => import("./pages/SendSurvey"));
const ViewSurvey = lazy(() => import("./pages/ViewSurvey"));
const AdminSurvey = lazy(() => import("./pages/AdminSurvey"));
const SchoolSurvey = lazy(() => import("./pages/SchoolSurvey"));
const SurveyInvites = lazy(() => import("./pages/SurveyInvites"));
const ReportBySchool = lazy(() => import("./pages/ReportBySchool"));
const Value = lazy(() => import("./pages/static/Value"));
const OrderReports = lazy(() => import("./pages/static/OrderReports"));
const Pricing = lazy(() => import("./pages/static/Pricing"));
const InviteLogin = lazy(() => import("./auth/InviteLogin"));
const ViewReceivedReports = lazy(() => import("./pages/ViewReceivedReports"));
const CodeManage = lazy(() => import("./pages/CodeManage"));
const SCLA = lazy(() => import("./pages/static/NECLTC"));
const More = lazy(() => import("./pages/static/More"));
const Validation = lazy(() => import("./pages/static/Validation"));

export default class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      resent: false,
      resend_error: false,
      sccate_admin: tl.user_is_sccate_admin(),
      user_can_manage_school: tl.user_can_manage_school(),
      sccate_admin_restricted: tl.user_is_restricted_sccate_admin(),
      user: tl.get_logged_in_user(),
    };
  }

  resend_confirmation_email = () => {
    tl.resend_confirmation_email()
      .then(() => this.setState({ resent: true }))
      .catch(() => this.setState({ resend_error: true }));
  };

  render() {
    if (!this.props.isAuthed) {
      return <Redirect to="/Login" />;
    }

    // If the screen is taller than wide, show mobile view
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const mobileView = screenHeight > screenWidth;

    if (mobileView) {
      console.log("return mobile view");
      return (
        <Router>
          <Suspense fallback={<Loading />}>
            <SidebarMenu
              logged_in={true}
              user_can_manage={this.state.user_can_manage_school}
              sccate_admin={this.state.sccate_admin}
              show={this.props.show}
              showHandler={this.props.showHandler}
              sccate_admin_restricted={this.state.sccate_admin_restricted}
            />
            <div className="mobile">
              {!this.state.user.email_confirmed && !this.state.resent && (
                <div className="global-message warn">
                  You have not yet confirmed your e-mail. Until you visit your
                  inbox and click the e-mail verification link you will not be
                  able to take this for a school.{" "}
                  <button onClick={() => this.resend_confirmation_email()}>
                    Resend Confirmation Email
                  </button>
                </div>
              )}
              {!this.state.user.email_confirmed &&
                !this.state.resent &&
                this.state.resend_error && (
                  <div className="global-message warn">
                    Error sending, please try again later or contact
                    info@s-ccate.org for help
                  </div>
                )}
              {!this.state.user.email_confirmed && this.state.resent && (
                <div className="global-message success">
                  Message sent, please check your inbox, including the spam
                  folder, for the validation email
                </div>
              )}

              <Route path="/Surveys/Take/:id" exact component={TakeSurvey} />

              {this.state.sccate_admin ? (
                // sccate admin
                <div>
                  <Route path="/" exact component={Dashboard} />
                  <Route path="/Surveys/" exact component={SurveyInvites} />
                  <Route
                    path="/Surveys/Manage/:id"
                    exact
                    component={ManageSurvey}
                  />
                  <Route
                    path="/Surveys/View/:id"
                    exact
                    component={ViewSurvey}
                  />
                </div>
              ) : // school admin
              this.state.user_can_manage_school ? (
                <div>
                  <Route
                    path="/"
                    exact
                    render={(props) => (
                      <Welcome
                        {...props}
                        isAuthed={true}
                        userCanManage={this.state.user_can_manage_school}
                      />
                    )}
                  />
                  <Route path="/Surveys/" exact component={SurveyInvites} />
                  <Route path="/Invites/" exact component={ListSurveys} />
                  <Route
                    path="/Surveys/View/:id"
                    exact
                    component={ViewSurvey}
                  />
                  <Route path="/Reports/" exact component={ReportBySchool} />
                  <Route
                    path="/Surveys/Send/:id/:schoolid"
                    exact
                    component={SendSurvey}
                  />
                  <Route
                    path="/Reports/Received/:schoolid"
                    exact
                    component={ViewReceivedReports}
                  />
                </div>
              ) : (
                <div>
                  <Route
                    path="/"
                    exact
                    render={(props) => <Welcome {...props} isAuthed={true} />}
                  />
                  <Route path="/Surveys" exact component={SurveyInvites} />
                </div>
              )}

              {(this.state.sccate_admin_restricted ||
                this.state.sccate_admin) && (
                <>
                  <Route
                    path="/Reports/School/Report/:id"
                    exact
                    component={SchoolSurvey}
                  />
                  <Route path="/AdminManage/" exact component={ListSurveys} />
                  <Route
                    path="/Surveys/Send/:id"
                    exact
                    component={SendSurvey}
                  />
                  <Route
                    path="/Surveys/Admin/Report/:id"
                    exact
                    component={AdminSurvey}
                  />
                  <Route path="/Reports/" exact component={ReportBySchool} />
                  <Route
                    path="/Reports/School/"
                    exact
                    component={ListSurveys}
                  />
                  <Route
                    path="/Surveys/Send/:id/:schoolid"
                    exact
                    component={SendSurvey}
                  />
                  <Route
                    path="/Reports/Received/:schoolid"
                    exact
                    component={ViewReceivedReports}
                  />
                  <Route
                    path="/DemoBreakdown"
                    exact
                    component={SccateReportV2}
                  />
                  <Route path="/CodeManage" exact component={CodeManage} />
                </>
              )}

              <Route
                path="/TrialVersion/:id"
                exact
                render={(props) => <ViewSurvey {...props} trial={true} />}
              />

              <Route
                path="/Setup/:userid/:code"
                render={(props) => (
                  <Setup {...props} isAuthed={this.props.isAuthed} />
                )}
              />
              <Route path="/Pricing" exact component={Pricing} />
              <Route path="/scla" exact component={SCLA} />
              <Route path="/Value" exact component={Value} />
              <Route path="/Validation" exact component={Validation} />
              <Route path="/OrderingReports" exact component={OrderReports} />
              <Route path="/More" exact component={More} />
              <Route
                path="/ThankYou"
                exact
                render={(props) => (
                  <ThankYou
                    {...props}
                    isAuthed={this.props.isAuthed}
                    trial={false}
                  />
                )}
              />
              <Route
                path="/TrialThankYou"
                exact
                render={(props) => (
                  <ThankYou
                    {...props}
                    isAuthed={this.props.isAuthed}
                    trial={true}
                  />
                )}
              />
              <Route path="/Account" exact component={Account} />
              <Route
                path="/InviteLogin/:id"
                exact
                render={(props) => (
                  <InviteLogin {...props} isAuthed={this.props.isAuthed} />
                )}
              />
            </div>
            <div className="clearfix"></div>
          </Suspense>
        </Router>
      );
    }

    return !this.props.isAuthed ? (
      <Redirect to="/Login" />
    ) : (
      <Router>
        <Suspense fallback={<Loading />}>
          <SidebarMenu
            logged_in={true}
            user_can_manage={this.state.user_can_manage_school}
            sccate_admin={this.state.sccate_admin}
            sccate_admin_restricted={this.state.sccate_admin_restricted}
          />
          <div className="main">
            {!this.state.user.email_confirmed && !this.state.resent ? (
              <div className="global-message warn">
                You have not yet confirmed your e-mail. Until you visit your
                inbox and click the e-mail verification link you will not be
                able to take this for a school.{" "}
                <button onClick={() => this.resend_confirmation_email()}>
                  Resend Confirmation Email
                </button>
              </div>
            ) : null}
            {!this.state.user.email_confirmed &&
            !this.state.resent &&
            this.state.resend_error ? (
              <div className="global-message warn">
                Error sending, please try again later or contact
                info@s-ccate.org for help
              </div>
            ) : null}
            {!this.state.user.email_confirmed && this.state.resent ? (
              <div className="global-message success">
                Message sent, please check your inbox, including the spam
                folder, for the validation email
              </div>
            ) : null}
            <Route path="/Surveys/Take/:id" exact component={TakeSurvey} />
            {this.state.sccate_admin ? (
              <div>
                <Route path="/" exact component={Dashboard} />
                <Route path="/Surveys/" exact component={SurveyInvites} />
                <Route
                  path="/Surveys/Manage/:id"
                  exact
                  component={ManageSurvey}
                />
                <Route path="/Surveys/View/:id" exact component={ViewSurvey} />
              </div>
            ) : this.state.user_can_manage_school ? (
              <div>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Welcome
                      {...props}
                      isAuthed={true}
                      userCanManage={this.state.user_can_manage_school}
                    />
                  )}
                />
                <Route path="/Surveys/" exact component={SurveyInvites} />
                <Route path="/Invites/" exact component={ListSurveys} />
                <Route path="/Surveys/View/:id" exact component={ViewSurvey} />
                <Route path="/Reports/" exact component={ReportBySchool} />
                <Route
                  path="/Surveys/Send/School/:schoolid"
                  exact
                  component={SendSurvey}
                />
                <Route
                  path="/Reports/Received/:schoolid"
                  exact
                  component={ViewReceivedReports}
                />
              </div>
            ) : (
              <div>
                <Route
                  path="/"
                  exact
                  render={(props) => <Welcome {...props} isAuthed={true} />}
                />
                <Route path="/Surveys" exact component={SurveyInvites} />
              </div>
            )}
            {this.state.sccate_admin_restricted || this.state.sccate_admin ? (
              <>
                <Route
                  path="/Reports/School/Report/:id"
                  exact
                  component={SchoolSurvey}
                />
                <Route path="/AdminManage/" exact component={ListSurveys} />
                <Route
                  path="/Surveys/Send/Survey/:surveyid"
                  exact
                  component={SendSurvey}
                />
                <Route
                  path="/Surveys/Send/School/:schoolid"
                  exact
                  component={SendSurvey}
                />
                <Route
                  path="/Surveys/Admin/Report/:id"
                  exact
                  component={AdminSurvey}
                />
                <Route path="/Reports/" exact component={ReportBySchool} />
                <Route path="/Reports/School/" exact component={ListSurveys} />
                <Route
                  path="/Reports/Received/:schoolid"
                  exact
                  component={ViewReceivedReports}
                />
                <Route path="/DemoBreakdown" exact component={SccateReportV2} />
                <Route path="/CodeManage" exact component={CodeManage} />
              </>
            ) : null}
            <Route
              path="/TrialVersion/:id"
              exact
              render={(props) => <ViewSurvey {...props} trial={true} />}
            />
            <Route
              path="/Setup/:userid/:code"
              render={(props) => (
                <Setup {...props} isAuthed={this.props.isAuthed} />
              )}
            />
            <Route path="/Pricing" exact component={Pricing} />
            <Route path="/scla" exact component={SCLA} />
            <Route path="/Value" exact component={Value} />
            <Route path="/Validation" exact component={Validation} />
            <Route path="/OrderingReports" exact component={OrderReports} />
            <Route path="/More" exact component={More} />
            <Route
              path="/ThankYou"
              exact
              render={(props) => (
                <ThankYou
                  {...props}
                  isAuthed={this.props.isAuthed}
                  trial={false}
                />
              )}
            />
            <Route
              path="/TrialThankYou"
              exact
              render={(props) => (
                <ThankYou
                  {...props}
                  isAuthed={this.props.isAuthed}
                  trial={true}
                />
              )}
            />
            <Route path="/Account" exact component={Account} />
            <Route
              path="/InviteLogin/:id"
              exact
              render={(props) => (
                <InviteLogin {...props} isAuthed={this.props.isAuthed} />
              )}
            />
          </div>
          <div className="clearfix"></div>
        </Suspense>
      </Router>
    );
  }
}
