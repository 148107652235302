import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DataUsage from "@mui/icons-material/DataUsage";
import Edit from "@mui/icons-material/Edit";
import Person from "@mui/icons-material/Person";
import "../../App.css";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import tl_logo from "../../img/tl_logo.png";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import People from "@mui/icons-material/People";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

interface Props extends RouteComponentProps {
  sccate_admin: boolean;
  sccate_admin_restricted: boolean;
  user_can_manage: boolean;
  show: boolean;
  logged_in: boolean;
  showHandler: () => void;
}

console.log(process.env);

type State = {
  show: boolean;
};

class SidebarMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: props.show,
    };
  }

  toggleDrawer = (value: boolean) => () => {
    this.setState({
      show: value,
    });
  };

  render() {
    // If the screen is taller than wide, show mobile view
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const mobileView = screenHeight > screenWidth;

    const navList = (
      <div>
        <List>
          <ListItem button key={"welcome-invisible"} component={NavLink} to="/">
            <ListItemIcon>
              <DashboardIcon style={{ background: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary={this.props.sccate_admin ? "Dashboard" : "Welcome"}
            />
          </ListItem>

          <ListItem button key={"welcomeDashboard"} component={NavLink} to="/">
            <ListItemIcon>
              <DashboardIcon style={{ background: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary={this.props.sccate_admin ? "Dashboard" : "Welcome"}
            />
          </ListItem>

          {this.props.sccate_admin && (
            <ListItem
              button
              key={"surveys"}
              component={NavLink}
              to={{ pathname: "/surveys", state: { sccate_admin: true } }}
            >
              <ListItemIcon>
                <Edit style={{ background: "none" }} />
              </ListItemIcon>
              <ListItemText primary={"Surveys"} />
            </ListItem>
          )}

          {!this.props.sccate_admin && this.props.user_can_manage && (
            <ListItem
              button
              key={"surveys"}
              component={NavLink}
              to={{ pathname: "/surveys", state: { sccate_admin: false } }}
            >
              <ListItemIcon>
                <Edit style={{ background: "none" }} />
              </ListItemIcon>
              <ListItemText primary={"Surveys"} />
            </ListItem>
          )}

          {(this.props.user_can_manage || this.props.sccate_admin) && (
            <ListItem
              button
              key={"reports"}
              component={NavLink}
              to={{
                pathname: "/reports",
                state: {
                  sccate_admin: this.props.sccate_admin,
                  school_admin: this.props.user_can_manage,
                },
              }}
            >
              <ListItemIcon>
                <DataUsage style={{ background: "none" }} />
              </ListItemIcon>
              <ListItemText primary={"Reports"} />
            </ListItem>
          )}

          <ListItem button key={"value"} component={NavLink} to="/value">
            <ListItemIcon>
              <SettingsIcon style={{ background: "none" }} />
            </ListItemIcon>
            <ListItemText primary={"Value"} />
          </ListItem>
          <ListItem button key={"scla"} component={NavLink} to="/scla">
            <ListItemIcon>
              <People style={{ background: "none" }} />
            </ListItemIcon>
            <ListItemText primary={"CSLA"} />
          </ListItem>

          <ListItem button key={"pricing"} component={NavLink} to="/pricing">
            <ListItemIcon>
              <PaymentIcon style={{ background: "none" }} />
            </ListItemIcon>
            <ListItemText primary={"Pricing"} />
          </ListItem>

          <ListItem button key={"more"} component={NavLink} to="/more">
            <ListItemIcon>
              <InfoIcon style={{ background: "none" }} />
            </ListItemIcon>
            <ListItemText primary={"General Information"} />
          </ListItem>
        </List>
      </div>
    );

    if (mobileView) {
      return (
        <div>
          <Drawer open={this.props.show} onClose={this.props.showHandler}>
            <div tabIndex={0} role="button" onClick={this.props.showHandler}>
              {navList}
            </div>
            <div>
              Phone: 1-833-4SCCATE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </Drawer>
        </div>
      );
    }

    return (
      <div className="sidebar-menu">
        <div className="inner">
          <ul>
            <li>
              <NavLink to="/" exact>
                <DashboardIcon style={{ background: "none" }} />{" "}
                {this.props.sccate_admin ? "Dashboard" : "Welcome"}
              </NavLink>
            </li>

            {this.props.sccate_admin && (
              <li>
                <NavLink
                  to={{ pathname: "/surveys", state: { sccate_admin: true } }}
                  exact={false}
                >
                  <Edit style={{ background: "none" }} /> Surveys
                </NavLink>
              </li>
            )}
            {!this.props.sccate_admin && this.props.user_can_manage && (
              <li>
                <NavLink
                  to={{ pathname: "/surveys", state: { sccate_admin: false } }}
                  exact={false}
                >
                  <Edit style={{ background: "none" }} /> Surveys
                </NavLink>
              </li>
            )}
            {!this.props.sccate_admin &&
              !this.props.user_can_manage &&
              this.props.logged_in && (
                <li>
                  <NavLink
                    to={{
                      pathname: "/surveys",
                      state: { sccate_admin: false },
                    }}
                  >
                    <Edit style={{ background: "none" }} /> Surveys
                  </NavLink>
                </li>
              )}

            {(this.props.user_can_manage || this.props.sccate_admin) && (
              <li>
                <NavLink
                  to={{
                    pathname: "/reports",
                    state: {
                      sccate_admin: this.props.sccate_admin,
                      school_admin: this.props.user_can_manage,
                    },
                  }}
                >
                  <DataUsage style={{ background: "none" }} />{" "}
                  {!this.props.sccate_admin ? "Manage" : "Reports"}
                </NavLink>
              </li>
            )}

            {
              <li>
                <NavLink to="/value">
                  <SettingsIcon style={{ background: "none" }} />
                  Value of S-CCATE
                </NavLink>
              </li>
            }

            {
              <li>
                <NavLink to="/csla">
                  <People style={{ background: "none" }} />
                  CSLA
                </NavLink>
              </li>
            }

            {
              <li>
                <NavLink to="/pricing">
                  <PaymentIcon style={{ background: "none" }} /> Products and
                  Pricing
                </NavLink>
              </li>
            }

            {
              <li>
                <NavLink to="/more">
                  <InfoIcon style={{ background: "none" }} />
                  About Us
                </NavLink>
              </li>
            }

            {this.props.logged_in && (
              <li>
                <NavLink to="/account">
                  <Person style={{ background: "none" }} /> Account
                </NavLink>
              </li>
            )}

            {(this.props.sccate_admin_restricted ||
              this.props.sccate_admin) && (
              <li>
                <NavLink to={{ pathname: "/AdminManage" }} exact={false}>
                  <Edit style={{ background: "none" }} /> Admin Manage
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <div className="powered-by">
          <div style={{ float: "left" }}>
            Version {process.env.REACT_APP_VERSION} &nbsp;|&nbsp; Powered by
          </div>
          <div style={{ float: "left" }}>
            <a href="https://app.myteachlink.com" target="_blank">
              <img
                src={tl_logo}
                style={{ width: "70px", marginLeft: "6px", marginTop: "3px" }}
              />
            </a>
          </div>
          <div style={{ clear: "both" }}></div>
          Copyright &copy; 2018 Center for Educational Improvement
        </div>
      </div>
    );
  }
}

const SidebarMenuWithRouter = withRouter(SidebarMenu);
export default SidebarMenuWithRouter;
