import React from 'react';
import table from '../../../img/sample-table.png'
import graph from '../../../img/sample-graph.png'
import interp from '../../../img/sample-interpretation.png'
import recs from '../../../img/sample-recs.png'

export default function ThankYou(props) {
    return (
        <div>
            <h3>Thank You!</h3>
            {!props.trial ? (
                <div className="panel large-pad">
                    Thank you for taking the S-CCATE assessment. Remember to receive results, 7 people from your school need to respond. View information <a href="/pricing" target="_blank">here</a> to find out how to obtain: 
                    <ul className='welcome-list'>
                        <li>A copy of your results for S-CCATE Basic, including graphic results and a summary of strengths, needs, and recommendations</li>
                        <li>Action Guides to support your school in selecting professional development about applying evidenced-based interventions</li>
                        <li>Additional information on the S-CCATE Suite of Services</li>
                    </ul>

                    To learn about special packages and discounts for districts, <a href="/pricing" target="_blank"> review this page</a> or contact Dr. Martha Staeheli, Executive Director of the Center for Educational Improvement at martha.staeheli@yale.edu.
                    <br />
                    <br />
                    To learn more about how you can use mindfulness in your classroom and school to alleviate stress and build a compassionate community, visit the Center for Educational Improvement's, <a href="http://www.edimprovement.org/" target="_blank">website</a>, <a href="https://twitter.com/Edimprove" target="_blank">Twitter</a>, and <a href="https://www.facebook.com/Edimprove" target="_blank">Facebook</a> pages and sign up for our <a href="https://visitor.r20.constantcontact.com/manage/optin?v=001effcjiBvUC792Nrtulckcp2ruMM3zTp1Qb4neGjjA8QAExuPVfuJLSkvyY6DqhosgyocsJV4aUzUQYeSctV6u7k3ruN3wMpM2a069bxTpSgZGEYyIrR73wFZDn-wJLpgpBsW_LGauy_q3227n91cKqGU6qsPXABIbwyzdR-s8G1CYZ_d1iLqUMiIE2mDeTLfeoa3vCH7ye6IfROjAEV3aw==" target="_blank">monthly e-Newsletter</a>.
                    <br /><br />
                    Now that you have completed S-CCATE, you can gain access to Basic S-CCATE reports, including graphs and comparative national data, identification of areas of strength, and recommendations for areas of needs, by <a href="/pricing" target="_blank">completing an order</a>.
                    <br /><br />
                    Optional services are also available to assist you and your school/district in addressing your needs and helping to develop more compassionate school environments. Check out our <a href="/pricing">S-CCATE Suite of Services</a>.
                </div>
            ) : (
                <div className="panel large-pad">
                    <b>Your trial is now complete.</b> Schools that sign up for S-CCATE receive:<br /><br />

                    Access to Basic S-CCATE reports, including graphs and comparative national data, with identification of areas of strength, and recommendations for areas of needs, by completing an order. Optional services are also available to assist you and your school/district in addressing your needs and helping to develop more compassionate school environments. Check out our S-CCATE Suite of Services.
                    <br /><br />
                    SAMPLE REPORT<br /><br />

                    Schools that sign up for S-CCATE receive access to Basic S-CCATE reports, including graphs and comparative national data, with identification of areas of strength, and recommendations for areas of needs, by completing an order.  See samples below.
                    
                    <br /><br />
                    <b>Samples from S-CCATE Report</b><br /><br />
                    <div className="report-samples">
                        <img src={interp} alt="Sample Interp" /><br /><br />
                        <img src={table} alt="Sample Table" /><br /><br />
                        <img src={graph} alt="Sample Grahp" /><br /><br />
                        <img src={recs} alt="Sample Recs" /><br /><br />
                    </div>

                    Optional services are also available to assist you and your school/district in addressing your needs and helping to develop more compassionate school environments. Check out our <a href="/pricing" target="_blank">S-CCATE Suite of Services</a>.<br /><br />

                    To proceed with signing up your school or district to complete the S-CCATE ask your school’s data manager (a technology manager or administrator assuming responsibility for S-CCATE in your school/district) to contact sales@s-ccate.org.
                </div>
            )}
        </div>
    )
}